import React, { Fragment } from "react";

import { withStyles } from "@material-ui/styles";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { hostInfo } from "../../../store";

import EditVisitMedicine from "./editcomponents/EditMedicine";
import EditVisitReport from "./editcomponents/EditReport";

import FormGroup from "@material-ui/core/FormGroup";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { visitFormfields } from "../../../store";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 230,
  },
  // countryControl: {
  //   minWidth: 150,
  // },
  countryControl: {
    marginRight: theme.spacing(1),
    minWidth: 170,
  },
  insurance: {
    marginTop: theme.spacing(2),
  },
  bloodGroupFld: {
    minWidth: 150,
  },
  seprator: {
    margin: theme.spacing(2),
  },
  btnClass: {
    margin: theme.spacing(2),
  },
  seprator1: {
    marginTop: theme.spacing(2),
  },
  seprator2: {
    width: "100%",
    ...theme.typography.body2,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  multilineFld: {
    display: "flex",
    flexWrap: "wrap",
  },
  amountGreen: {
    borderColor: "pink",
  },
  specialOutline: {
    borderColor: "pink",
  },
  presc: {
    width: "100%",
  },
  freeTextNotes: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
});

// var countryStateCity = require("country-state-city").default;

export default withStyles(styles)(
  class EditVisit extends React.Component {
    state = {
      drRefState: {
        refMark: false,
        refDisabled: true,
        refGrpDisabled: true,
      },
      drGroups: [],
      drList: [],
      formdetail: {
        id: 0,
        weight: 0,
        height: 0,
        bp: "",
        pulse: 0,
        spo: 0,
        temperature: 0,
        age: 0,
        ptNotes: [],
        drNotes: [],
        notes: "",
        diaNotes: "",
        amount: 0,
        amountPaid: 0,
        drRef: "",
        drRefInfo: "",
        medList: [],
        repList: [],
        drGrp: "",

        weightError: false,
        ageError: false,
        heightError: false,
        bpError: false,
        spoError: false,
        temperatureError: false,
        pulseError: false,
        weightErrorTxt: "",
        ageErrorTxt: "",
        heightErrorTxt: "",
        bpErrorTxt: "",
        spoErrorTxt: "",
        temperatureErrorTxt: "",
        pulseErrorTxt: "",
        amountClass: "",
      },
    };

    medSug = [];
    repSug = [];
    drSug = [];
    symSug = [];
    disSug = [];

    validate = () => {
      let isError = false;
      const { formdetail } = this.state;

      // console.log(this.state);

      // Clean old error status
      formdetail.userIdError = false;
      formdetail.drIdError = false;

      formdetail.userIdErrorTxt = "";
      formdetail.drIdErrorTxt = "";

      // console.log()

      // User id Validation----
      // 1. Length
      // 2. valid id number
      // if (
      //   !this.isUserIdPresentInArray(this.state.userList, formdetail.userId)
      // ) {
      //   isError = true;
      //   formdetail.userIdError = true;
      //   formdetail.userIdErrorTxt =
      //     "Invalid user. Please select proper username";
      // }

      // console.log(
      //   "userid: " +
      //     formdetail.userId +
      //     " and length: " +
      //     formdetail.userId.length
      // );
      // Doctor id Validation----
      // 1. Length
      // 2. special character check
      // if (!this.isDrIdPresentInArray(this.state.drList, formdetail.drId)) {
      //   isError = true;
      //   formdetail.drIdError = true;
      //   formdetail.drIdErrorTxt = "Invalid doctor. Please select proper doctor";
      // }
      // console.log(
      //   "drid: " + formdetail.drId + " and length: " + formdetail.drId.length
      // );

      // this.setState({
      //   ...this.state.formdetail,
      // });

      return isError;
    };

    handleSave = () => {
      // const err = this.validate();

      let { formdetail } = this.state;

      // console.log("Final from object...111");

      let medList = formdetail.medList.filter(
        (x, idx) => x.medName !== "" || idx === 0
      );
      let repList = formdetail.repList.filter(
        (x, idx) => x.repName !== "" || idx === 0
      );
      // console.log(repList);
      // console.log(formdetail.weight);
      let formObj = {
        id: this.props.visit.id,
        weight: formdetail.weight,
        bp: formdetail.bp,
        spo: formdetail.spo,
        temperature: formdetail.temperature,
        pulse: formdetail.pulse,
        age: formdetail.age,
        height: formdetail.height,
        meds: JSON.stringify(medList),
        reps: JSON.stringify(repList),
        amount: formdetail.amount,
        paid_amount: formdetail.amountPaid,
        pt_notes: JSON.stringify(formdetail.ptNotes),
        dr_notes: JSON.stringify(formdetail.drNotes),
        notes: formdetail.notes,
        dia_notes: formdetail.diaNotes,
        ref_dr: formdetail.drRef,
        ref_grp: formdetail.drGrp,
        ref_info: formdetail.drRefInfo,
        is_ref: this.state.drRefState.refMark ? 1 : 0,
      };
      // console.log("Final from object...");
      // console.log(formObj);
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      let url = hostInfo + "/updatecasevisit";
      // console.log(url);
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(formObj),
      });

      this.props.handleSubmit();

      //
      // Update in Visit value
      //
      // Call the submit function of parent
      //
    };

    async componentDidMount() {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      // console.log("Component DID MOUNT!");
      let url = hostInfo + "/getmeds";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in meds ... ");
          // console.log(data);
          if (data != null) this.medSug = data;
        });

      // repSug -------------------
      url = hostInfo + "/getreps";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in reps ... ");
          // console.log(data);
          if (data != null) this.repSug = data;
        });

      // drSug ------------------
      url = hostInfo + "/getdrs";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in drs ... ");
          // console.log(data);
          if (data != null) this.drSug = data;
        });

      // disSug ------------------
      url = hostInfo + "/getdis";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in dis ... ");
          // console.log(data);
          if (data != null) this.disSug = data;
        });

      // symSug ------------------
      url = hostInfo + "/getsymt";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in sym ... ");
          // console.log(data);
          if (data != null) this.symSug = data;
        });

      url = hostInfo + "/getdrgroups";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("dr Groups got ... ");
          // console.log(data);
          if (data != null) this.setState({ drGroups: data });
        });

      let visit = this.props.visit;
      let drRefState = { refMark: visit.is_ref === 0 ? false : true };

      this.setState({ ...this.state, drRefState });
      // console.log(drRefState);
      let formdetail = {
        id: visit.id,
        weight: visit.weight,
        bp: visit.bp,
        spo: visit.spo,
        temperature: visit.temperature,
        pulse: visit.pulse,
        age: visit.age,
        height: visit.height,
        ptNotes: JSON.parse(visit.patient_notes),
        drNotes: JSON.parse(visit.dr_notes),
        notes: visit.notes,
        diaNotes: visit.dia_notes,
        amount: visit.amount,
        amountPaid: visit.paid_amount,
        drRef: visit.ref_dr,
        drGrp: visit.ref_grp,
        drRefInfo: visit.ref_info,
        medList: JSON.parse(visit.medicine_info),
        repList: JSON.parse(visit.report_info),
        // drGrp: this.drGroups.length > 0 ? this.drGroups[0] : "",

        weightError: false,
        ageError: false,
        heightError: false,
        bpError: false,
        spoError: false,
        temperatureError: false,
        pulseError: false,
        weightErrorTxt: "",
        ageErrorTxt: "",
        heightErrorTxt: "",
        bpErrorTxt: "",
        spoErrorTxt: "",
        temperatureErrorTxt: "",
        pulseErrorTxt: "",
        amountClass: "",
      };

      this.setState({ ...this.state, formdetail });
    }

    handleChangeCheckbox = (event) => {
      if (event.target.checked === true) {
        let drRefState = { ...this.state.drRefState };
        drRefState.refGrpDisabled = false;
        drRefState.refMark = true;
        this.setState(() => ({ drRefState }));
      } else {
        let drRefState = { ...this.state.drRefState };
        drRefState.refGrpDisabled = true;
        drRefState.refMark = false;
        this.setState({ drRefState });
        let formdetail = { ...this.state.formdetail };

        formdetail.drGrp = null;
        formdetail.drRef = null;
        formdetail.drRefInfo = null;
        this.setState({ formdetail });
      }
    };

    handleDrGrpChange = (e, value) => {
      // console.log("Inside handle dr Group change change---");
      // console.log(value);

      let drRefState = { ...this.state.drRefState };
      this.setState({
        ...this.state,
        formdetail: { ...this.state.formdetail, drRef: "", drGrp: value.name },
      });
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      if (value.id > 0) {
        drRefState.refDisabled = false;

        // Fetch drlist for given dr group ------------------
        let url = hostInfo + "/getdrdetails?group_id=" + value.id;
        fetch(url, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data != null) {
              this.setState({ drList: data });
            } else {
              this.setState({ drList: [] });
            }
          });
      } else {
        drRefState.refDisabled = false;
        // drRefState.refGrpDisabled = false;
      }
      this.setState(() => ({ drRefState }));
    };

    handleDrChange = (e, name, value) => {
      // console.log("Inside handle dr change change---");
      // console.log(id);
      // console.log(name);
      // console.log(value.name);

      let formdetail = { ...this.state.formdetail };
      formdetail.drRef = value.name;
      formdetail.drRefInfo = value.description + " (" + value.mobile + ")";
      this.setState({ formdetail });
    };

    handleOnChange = (e, name) => {
      const value = e.target.value;
      // console.log("Value change --- ");
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMultiFld = (name, value) => {
      // console.log("------ Inside Multifiled handler ------");
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleBGChange = (name, value) => {
      // console.log(name);
      // console.log(value);

      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMedChange = (id, name, value) => {
      // console.log("Inside handleMed change---");
      // console.log(id);
      // console.log(name);
      // console.log(value);

      let medList = [...this.state.formdetail.medList];
      medList[id][name] = value;

      this.setState({ medList });
      // console.log(medList);
    };
    handleRepChange = (id, name, value) => {
      // console.log("Inside Rep change---");
      // console.log(id);
      // console.log(name);
      // console.log(value);

      let repList = [...this.state.formdetail.repList];
      repList[id][name] = value;

      this.setState({ repList });
    };

    addMedRow = (e) => {
      this.setState((prevState) => ({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          medList: [
            ...prevState.formdetail.medList,
            {
              index: Math.random(),
              medName: "",
              // medType: "",
              medQnt: 1,
              medSchd: "",
              medTime: "After Eat",
              medCount: 0,
            },
          ],
        },
      }));
    };

    addRepRow = (e) => {
      this.setState((prevState) => ({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          repList: [
            ...prevState.formdetail.repList,
            {
              index: Math.random(),
              repName: "",
            },
          ],
        },
      }));
    };

    deleteMedRow = (index) => {
      // console.log("Inside Delete med row.......");
      // console.log(this.state.formdetail.medList);
      // console.log(index);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          medList: this.state.formdetail.medList.filter(
            (s, idx) => index !== s.index
          ),
        },
      });
    };

    deleteRepRow = (index) => {
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          repList: this.state.formdetail.repList.filter(
            (s, idx) => index !== s.index
          ),
        },
      });
    };

    handleChange = (e) => {
      // console.log("Inside handle change..11.....");
      // console.log(e.target);
      // console.log(e.target.name);
      // console.log(e.target.type);
      // console.log(e.target.checked);
      // console.log(e.target.value);
      // console.log(e.target);
      // console.log(
      //   "change value-- id:  ",
      //   e.target.id,
      //   " name: ",
      //   e.target.name,
      //   " value: ",
      //   e.target.value
      // );
      if (e.target.type === "checkbox") {
        // console.log("type is checkbos... so going out...");
        return;
      } else if (
        ["medName", "medSchd", "medTime", "repName"].includes(e.target.name)
      ) {
        return;
      } else if (["medCount", "medOut", "medQnt"].includes(e.target.name)) {
        let medList = [...this.state.formdetail.medList];
        // console.log("------- Med List --------");
        // console.log(medList);
        medList[e.target.id][e.target.name] =
          e.target.type === "number"
            ? parseInt(e.target.value)
            : e.target.value;
        this.setState({ medList });
        // medList[e.target.id][e.target.name] = e.target.value;
        // } else if (["repName"].includes(e.target.name)) {
        //   let repList = [...this.state.formdetail.repList];
        //   repList[e.target.id][e.target.name] = e.target.value;
        //   this.setState({ repList });
      } else {
        this.setState({
          ...this.state,
          formdetail: {
            ...this.state.formdetail,
            [e.target.name]:
              e.target.type === "number"
                ? parseFloat(e.target.value)
                : e.target.value,
          },
        });
      }
    };

    render() {
      const {
        formdetail: {
          weight,
          bp,
          spo,
          temperature,
          pulse,
          age,
          height,
          ptNotes,
          drNotes,
          medList,
          repList,
          amount,
          amountPaid,
          notes,
          diaNotes,
          drRef,
          drGrp,
        },
        drRefState: { refGrpDisabled, refMark, refDisabled },
        drGroups,
        drList,
      } = this.state;

      const { classes, handleClose } = this.props;
      // console.log(refMark);

      return (
        <div>
          <Fragment>
            <form
              className={classes.root}
              autoComplete="off"
              onChange={this.handleChange}
            >
              <FormGroup row={true}>
                <div>
                  <TextField
                    //////////////////  weight
                    // className={classes.formControl}
                    autoFocus
                    margin="dense"
                    id="weight"
                    name="weight"
                    pattern="[0-9]*"
                    value={weight}
                    label="Weight"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-3">
                  <TextField
                    //////////////////  height
                    // className={classes.formControl}
                    // autoFocus
                    margin="dense"
                    id="height"
                    name="height"
                    pattern="[0-9]*"
                    value={height}
                    label="Height"
                    type="number"
                    inputProps={{ min: 0, step: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-3">
                  <TextField
                    //////////////////  age
                    // autoFocus
                    margin="dense"
                    id="spo"
                    name="spo"
                    value={spo}
                    pattern="[0-9]*"
                    label="SpO2"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                {/* <div className="ml-2">
                  <TextField
                    //////////////////  height
                    // className={classes.formControl}
                    // autoFocus
                    margin="dense"
                    id="age"
                    name="age"
                    pattern="[0-9]*"
                    value={age}
                    label="Age"
                    type="number"
                    inputProps={{ min: 0, step: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div> */}
              </FormGroup>
              <FormGroup row={true}>
                <div>
                  <TextField
                    margin="dense"
                    placeholder="120/80"
                    id="bp"
                    name="bp"
                    value={bp}
                    label="BP"
                    type="text"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm of Hg</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-3">
                  <TextField
                    //////////////////  height
                    // autoFocus
                    margin="dense"
                    id="pulse"
                    name="pulse"
                    pattern="[0-9]*"
                    value={pulse}
                    label="Pulse"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/ min</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-3">
                  <TextField
                    //////////////////  age
                    // autoFocus
                    margin="dense"
                    id="temperature"
                    name="temperature"
                    value={temperature}
                    pattern="[0-9]*"
                    label="Temperature"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">°F</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                      <Autocomplete
                        //////////////////// ptNotes
                        multiple
                        id="multi field"
                        classes={{ option: classes.autocomp }}
                        // autoFocus
                        size="small"
                        freeSolo
                        options={this.symSug}
                        value={ptNotes}
                        filterSelectedOptions
                        onChange={(e, newValue) => {
                          this.handleMultiFld("ptNotes", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // multiline
                            // rows={2}
                            variant="outlined"
                            label={visitFormfields.complain}
                            // placeholder="word"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs>
                      <Autocomplete
                        //////////////////// drNotes
                        multiple
                        id="multi field"
                        classes={{ option: classes.autocomp }}
                        // autoFocus
                        size="small"
                        freeSolo
                        options={this.disSug}
                        value={drNotes}
                        filterSelectedOptions
                        onChange={(e, newValue) => {
                          this.handleMultiFld("drNotes", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // multiline
                            // rows={2}
                            variant="outlined"
                            label={visitFormfields.diagnose}
                            // placeholder="Word"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                      <TextField
                        /////////////////////  notes
                        id="outlined-basic"
                        className={classes.freeTextNotes}
                        multiline
                        rows={2}
                        label={visitFormfields.notes}
                        placeholder={visitFormfields.notes}
                        name="notes"
                        variant="outlined"
                        value={notes}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        // autoFocus
                        id="outlined-basic"
                        className={classes.freeTextNotes}
                        multiline
                        rows={2}
                        label={visitFormfields.diaNotes}
                        placeholder={visitFormfields.diaNotes}
                        name="diaNotes"
                        variant="outlined"
                        value={diaNotes}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
                <FormGroup>
                  <Typography gutterBottom variant="h5" color="textSecondary">
                    Prescriptions
                  </Typography>
                  <Divider variant="middle" />
                  <EditVisitMedicine
                    add={this.addMedRow}
                    delete={this.deleteMedRow}
                    medList={medList}
                    handleChange={this.handleMedChange}
                    medSug={this.medSug}
                  />
                  <EditVisitReport
                    add={this.addRepRow}
                    delete={this.deleteRepRow}
                    repList={repList}
                    handleChange={this.handleRepChange}
                    repSug={this.repSug}
                  />
                </FormGroup>
                <Divider
                  variant="middle"
                  className={classes.seprator}
                  // borderColor={"red"}
                />
                <div className={classes.seprator1}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                      <TextField
                        ////////// amount
                        // autoFocus
                        label="Amount"
                        id="amount"
                        name="amount"
                        value={amount}
                        pattern="[0-9]*"
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        //////////// Amount paid
                        label="Amount Paid"
                        id="amountPaid"
                        name="amountPaid"
                        value={amountPaid}
                        pattern="[0-9]*"
                        variant="outlined"
                        type="number"
                        inputProps={{
                          classes: {
                            classes: { notchedOutline: classes.specialOutline },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={3}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        className={classes.refCls}
                      >
                        Refer
                        <Checkbox
                          checked={refMark}
                          onChange={(e) => this.handleChangeCheckbox(e)}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        id="drGrp"
                        margin="dense"
                        value={
                          this.state.drRefState.refMark === false
                            ? null
                            : drGrp
                            ? { name: drGrp }
                            : null
                        }
                        // value={{ name: drGrp }}
                        classes={{ option: classes.autocomp }}
                        // autoFocus
                        disableClearable
                        disabled={refGrpDisabled}
                        options={drGroups}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                          this.handleDrGrpChange(e, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Dr Grp"
                            name="drGrp"
                            margin="dense"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        id="drRef"
                        margin="dense"
                        classes={{ option: classes.autocomp }}
                        // autoFocus
                        value={
                          this.state.drRefState.refMark === false
                            ? null
                            : drRef
                            ? { name: drRef }
                            : null
                        }
                        // value={{ name: drRef }}
                        disableClearable
                        disabled={refDisabled || !refMark}
                        options={drList}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                          value.name === "" || option.name === value.name
                        }
                        onChange={(e, value) => {
                          this.handleDrChange(e, "drRef", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Dr Reference"
                            name="drRef"
                            margin="dense"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
              </FormGroup>
              <div>
                <Button
                  onClick={() => {
                    this.handleSave();
                  }}
                  color="primary"
                  variant="outlined"
                >
                  Save
                </Button>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                  className={classes.btnClass}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </Fragment>
        </div>
      );
    }
  }
);
