import React from "react";
import Chart from "react-apexcharts";
import { hostInfo } from "../../../../store";

class DailyCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: "test",
          data: [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          id: "daily-cases",
          // height: 400,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            // columnWidth: "10%",
            horizontal: false,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        title: {
          text: "Daily Visits",
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          type: "datetime",
          categories: [],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  }

  async componentDidMount() {
    const caseCount = [];
    const days = [];
    const getToken = () => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});
      return cookies.authToken;
    };

    let url = hostInfo + "/getcaseperday";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data !== null) {
          for (var i = 0; i < data.length; i++) {
            caseCount.push(data[i]["visit_count"]);
            days.push(data[i]["days"] + " GMT");
            // console.log(data[i]);
          }
        }
        this.setState({
          ...this.state,
          options: {
            chart: {
              type: "bar",
              id: "daily-cases",
              height: 350,
            },

            xaxis: {
              type: "datetime",
              categories: days,
            },
          },
          series: [
            {
              name: "Cases",
              data: caseCount,
            },
          ],
        });
      });
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        height={400}
        // options={this.state.options}
        // series={this.state.series}
        // type="bar"
        // width={800}
        // height={800}
      />
    );
  }
}

export default DailyCases;
