import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
// import { Button } from "@material-ui/core";
import ReactFileReader from "react-file-reader";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import { hostInfo } from "../../store";
import DataTables from "./DataTables";
import repIcon from "../../icons/rep.png";
import medIcon from "../../icons/meds.png";
import sympIcon from "../../icons/symp.png";
import disIcon from "../../icons/dis.png";

import csv from "csv";
import {
  Box,
  Button,
  Typography,
  // TablePagination,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import AddReport from "./components/AddReport";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    // marginBottom: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uploadBtn: {
    background: "#FF6085",
    borderRadius: 3,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#9BE27A",
    },
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  iconCls: {
    marginRight: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography component={"span"}>{children}</Typography>
          {/* {children} */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const validateData = (data) => {
  // console.log("Inside data validation...");
  // Data validation remain --------- TODO

  // console.log(data);

  return 0;
};

// Medicine -------------------------
const handleMedsUpload = (file) => {
  // console.log("Inside Med Upload...");
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // console.log("Uploaded file is xlsx...");
    uplofMedxlsx(file[0]);
  } else {
    // console.log("Uploaded file is not xlsx...");
    uplofMedcsv(file[0]);
  }
  window.location.reload();
  // console.log("fie " + file[0].name + " Uploaded Successfully");
};
const getToken = () => {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {});
  return cookies.authToken;
};

const uplofMedxlsx = (file) => {
  /* Boilerplate to set up FileReader */
  // const file = files[0].name;
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    // console.log("wsname ----- ", wsname);
    const ws = wb.Sheets[wsname];
    // console.log("wsname ----- ", wsname);

    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });
    if (!validateData(data)) {
      alert("uploading " + data.length + "medicine list");
      for (var i = 1; i < data.length; ) {
        var medList = [];
        for (var j = 0; i < data.length && j < 20; i++, j++) {
          if (
            data[i][0] === undefined ||
            data[i][0].match(/^ *$/) !== null ||
            data[i][1] === undefined ||
            data[i][1].match(/^ *$/) !== null
            // data[i][2] === undefined ||
            // data[i][2].match(/^ *$/) !== null
          ) {
            // console.log("Invalid medicine ----: ", data[i][0]);
            continue;
          }
          var med = {
            name: data[i][0],
            // company: data[i][1],
            type: data[i][1],
          };
          medList.push(med);
        }

        if (medList.length > 0) {
          let url = hostInfo + "/addmedlist";
          // console.log("POST data---: ", JSON.stringify(medList));
          fetch(url, {
            method: "POST",
            // mode: "no-cors",
            headers: {
              Accept: "*/*",
              "Content-type": "application/json; charset=UTF-8",

              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(medList),
          });
        }
      }
    } else {
      alert("Invalid data");
    }
    // console.log("Data from xlsx -------------");
    // console.log(data);
    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const uplofMedcsv = (file) => {
  const reader = new FileReader();
  reader.onload = function (e) {
    // console.log(e.target.result;);
    csv.parse(reader.result, (err, data) => {
      if (!validateData(data)) {
        alert(reader.result);
        for (var i = 1; i < data.length; ) {
          var medList = [];
          for (var j = 0; i < data.length && j < 20; i++, j++) {
            if (
              data[i][0] === undefined ||
              data[i][0].match(/^ *$/) !== null ||
              data[i][1] === undefined ||
              data[i][1].match(/^ *$/) !== null
              // data[i][2] === undefined ||
              // data[i][2].match(/^ *$/) !== null
            ) {
              continue;
            }
            var med = {
              name: data[i][0],
              // company: data[i][1],
              type: data[i][1],
            };
            medList.push(med);
          }
          if (medList.length > 0) {
            let url = hostInfo + "/addmedlist";
            fetch(url, {
              method: "POST",
              // mode: "no-cors",
              headers: {
                Accept: "*/*",
                "Content-type": "application/json; charset=UTF-8",

                Authorization: `Bearer ${getToken()}`,
              },
              body: JSON.stringify(medList),
            });
          }
        }
      } else {
        alert("Invalid data");
      }
      // console.log(data);
    });
  };
  reader.readAsText(file);
};

// Report Upload------------------------
const handleRepUpload = (file) => {
  // console.log("Inside Rep Upload...");
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // console.log("Uploaded file is xlsx...");
    uplofRepxlsx(file[0]);
  } else {
    // console.log("Uploaded file is not xlsx...");
    uplofRepcsv(file[0]);
  }
  window.location.reload();
  // console.log("fie " + file[0].name + " Uploaded Successfully");
};

const uplofRepxlsx = (file) => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });

    alert(reader.result);
    for (var i = 1; i < data.length; ) {
      // var symList = [];
      let results = [];

      for (var j = 1; i < data.length && j < 20; i++, j++) {
        if (data[i][0] === undefined || data[i][0].match(/^ *$/) !== null) {
          continue;
        }

        var rep = {
          report: data[i][0],
        };
        results.push(rep);
      }
      // console.log("pushing to Server: ", results);

      if (results.length > 0) {
        let url = hostInfo + "/addreplist";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(results),
        });
      }
    }

    // let results = [];
    // alert(reader.result);
    // for (var i = 1; i < data.length; i++) {
    //   if (data[i][0] === undefined || data[i][0].match(/^ *$/) !== null) {
    //     continue;
    //   }
    //   var rep = {
    //     report: data[i][0],
    //   };
    //   results.push(rep);
    // }
    // if (results.length > 0) {
    //   let url = "http://" + hostInfo + "/addreplist";
    //   fetch(url, {
    //     method: "POST",
    //     mode: "no-cors",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(results),
    //   });
    // }

    // console.log("Data from xlsx -------------");
    // console.log(data);
    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const uplofRepcsv = (file) => {
  // console.log("Inside the report handle file...");
  // console.log(file.name);

  var reader = new FileReader();
  reader.onload = function (e) {
    let results = [];
    // console.log(e.target.result);
    csv.parse(reader.result, (err, data) => {
      alert(reader.result);
      for (var i = 0; i < data.length; i++) {
        if (data[i][0] === undefined || data[i][0].match(/^ *$/) !== null) {
          continue;
        }
        var rep = {
          report: data[i][0],
        };
        results.push(rep);
      }
      if (results.length > 0) {
        let url = hostInfo + "/addreplist";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(results),
        });
      }

      // console.log(data);
    });

    // const json = csv2json(text);
    // Use reader.result
  };
  reader.readAsText(file);
};

// Dr Add ------------------------------
const handleDrRefUpload = (file) => {
  // console.log("Inside DrRef Upload...");
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // console.log("Uploaded file is xlsx...");
    uplofDrRefxlsx(file[0]);
  } else {
    // console.log("Uploaded file is not xlsx...");
    uplodDrRefcsv(file[0]);
  }
  // console.log("fie " + file[0].name + " Uploaded Successfully");
};

const uplofDrRefxlsx = (file) => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });

    alert(reader.result);
    for (var i = 1; i < data.length; ) {
      var drList = [];
      for (var j = 1; i < data.length && j < 20; i++, j++) {
        if (
          data[i][0] === undefined ||
          data[i][0].match(/^ *$/) !== null ||
          data[i][1] === undefined ||
          data[i][1].match(/^ *$/) !== null ||
          data[i][2] === undefined ||
          data[i][2].match(/^ *$/) !== null
        ) {
          continue;
        }
        var dr = {
          firstname: data[i][0],
          lastname: data[i][1],
          speciality: data[i][2],
          mobile: data[i][3],
        };
        drList.push(dr);
      }
      if (drList.length > 0) {
        let url = hostInfo + "/adddrinfo";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(drList),
        });
      }
    }
    // console.log("Data from xlsx -------------");
    // console.log(data);
    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const uplodDrRefcsv = (file) => {
  var reader = new FileReader();
  reader.onload = function (e) {
    // console.log(e.target.result);
    csv.parse(reader.result, (err, data) => {
      if (!validateData(data)) {
        alert(reader.result);
        for (var i = 0; i < data.length; ) {
          var drList = [];
          for (var j = 0; i < data.length && j < 20; i++, j++) {
            if (
              data[i][0] === undefined ||
              data[i][0].match(/^ *$/) !== null ||
              data[i][1] === undefined ||
              data[i][1].match(/^ *$/) !== null ||
              data[i][2] === undefined ||
              data[i][2].match(/^ *$/) !== null
            ) {
              continue;
            }
            var dr = {
              firstname: data[i][0],
              lastname: data[i][1],
              speciality: data[i][2],
              mobile: data[i][3],
            };
            drList.push(dr);
          }
          if (drList.length > 0) {
            let url = hostInfo + "/adddrinfo";
            fetch(url, {
              method: "POST",
              // mode: "no-cors",
              headers: {
                Accept: "*/*",
                "Content-type": "application/json; charset=UTF-8",

                Authorization: `Bearer ${getToken()}`,
              },
              body: JSON.stringify(drList),
            });
          }
        }
      } else {
        alert("Invalid data");
      }
      // console.log(data);
    });

    // const json = csv2json(text);
    // Use reader.result
  };
  reader.readAsText(file);
};

//Disease---------------------------------
const handleDesUpload = (file) => {
  // console.log("Inside Des Upload...");
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // console.log("Uploaded file is xlsx...");
    UploadDesxlsx(file[0]);
  } else {
    // console.log("Uploaded file is not xlsx...");
    UploadDescsv(file[0]);
  }
  window.location.reload();
  // console.log("fie " + file[0].name + " Uploaded Successfully");
};

const UploadDesxlsx = (file) => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });

    alert(reader.result);
    for (var i = 1; i < data.length; ) {
      var desList = [];
      for (var j = 1; i < data.length && j < 20; i++, j++) {
        if (
          data[i][0] === undefined ||
          data[i][0].match(/^ *$/) !== null ||
          data[i][1] === undefined ||
          data[i][1].match(/^ *$/) !== null
        ) {
          continue;
        }
        var des = {
          name: data[i][0],
          description: data[i][1],
        };
        desList.push(des);
      }
      if (desList.length > 0) {
        let url = hostInfo + "/adddislist";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(desList),
        });
      }
    }
    // console.log("Data from xlsx -------------");
    // console.log(data);
    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const UploadDescsv = (file) => {
  var reader = new FileReader();
  reader.onload = function (e) {
    // console.log(e.target.result);
    csv.parse(reader.result, (err, data) => {
      if (!validateData(data)) {
        alert(reader.result);
        for (var i = 0; i < data.length; ) {
          var desList = [];
          for (var j = 0; i < data.length && j < 20; i++, j++) {
            if (
              data[i][0] === undefined ||
              data[i][0].match(/^ *$/) !== null ||
              data[i][1] === undefined ||
              data[i][1].match(/^ *$/) !== null
            ) {
              continue;
            }
            var des = {
              name: data[i][0],
              description: data[i][1],
            };
            desList.push(des);
          }
          if (desList.length > 0) {
            let url = hostInfo + "/adddislist";
            fetch(url, {
              method: "POST",
              // mode: "no-cors",
              headers: {
                Accept: "*/*",
                "Content-type": "application/json; charset=UTF-8",

                Authorization: `Bearer ${getToken()}`,
              },
              body: JSON.stringify(desList),
            });
          }
        }
      } else {
        alert("Invalid data");
      }
      // console.log(data);
    });
  };
  reader.readAsText(file[0]);
};

//Symptoms---------------------------------
const handleSymptUpload = (file) => {
  // console.log("Inside Sympt Upload...");
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // console.log("Uploaded file is xlsx...");
    UploadSymptxlsx(file[0]);
  } else {
    // console.log("Uploaded file is not xlsx...");
    UploadSymptcsv(file[0]);
  }
  // fetchSymptom();
  // console.log("fie " + file[0].name + " Uploaded Successfully");
  window.location.reload();
};

const UploadSymptxlsx = (file) => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });

    alert(reader.result);
    for (var i = 1; i < data.length; ) {
      var symList = [];
      for (var j = 1; i < data.length && j < 20; i++, j++) {
        if (data[i][0] === undefined || data[i][0].match(/^ *$/) !== null) {
          continue;
        }
        var des = {
          name: data[i][0],
        };
        symList.push(des);
      }
      // console.log("pushing to Server: ", symList);

      if (symList.length > 0) {
        let url = hostInfo + "/addsymptlist";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(symList),
        });
      }
    }
    // console.log("Data from xlsx -------------");
    // console.log(data);

    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const UploadSymptcsv = (file) => {
  var reader = new FileReader();
  reader.onload = function (e) {
    // const text = e.target.result;
    // console.log(e.target.result);
    csv.parse(reader.result, (err, data) => {
      if (!validateData(data)) {
        alert(reader.result);
        for (var i = 0; i < data.length; ) {
          var desList = [];
          for (var j = 0; i < data.length && j < 20; i++, j++) {
            if (data[i][0] === undefined || data[i][0].match(/^ *$/) !== null) {
              continue;
            }
            var des = {
              name: data[i][0],
            };
            desList.push(des);
          }
          if (desList.length > 0) {
            let url = hostInfo + "/addsympt";
            fetch(url, {
              method: "POST",
              // mode: "no-cors",
              headers: {
                Accept: "*/*",
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${getToken()}`,
              },
              body: JSON.stringify(desList),
            });
          }
        }
      } else {
        alert("Invalid data");
      }
      // console.log(data);
    });
  };
  reader.readAsText(file[0]);
};

function ReportListTable(props) {
  const [reps, setReps] = React.useState([]);

  const EditData = (name) => {
    setReps(name);
  };
  const { values, open, handleClickClose } = props;
  // console.log(reps);
  const appendNewReps = (name) => {
    // console.log(name);
    setReps([...reps, name]);
  };

  const deleteData = (itemId) => {
    setReps(reps.filter((item) => item.Id !== itemId));
  };
  const fetchData = async () => {
    try {
      var url = hostInfo + "/getrepsinfo";
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // console.log("Fetched data:", data);
      if (data != null) {
        setReps(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch initial data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // The rest of your component...

  return (
    <div>
      <DataTables
        types="reps"
        dataList={reps}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          appendNewItem={appendNewReps}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

// Medicine *************************
function MedListTable(props) {
  const [meds, setMeds] = React.useState([]);

  const EditData = (name) => {
    setMeds(name);
  };
  const appendNewMeds = (name) => {
    setMeds([...meds, name]);
  };
  const deleteData = (itemId) => {
    setMeds(meds.filter((item) => item.Id !== itemId));
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchMedicine() {
      let url = hostInfo + "/getmedsinfo";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data medicine data  ----11111111111111");
          // console.log(data);
          if (data != null) setMeds(data);
        });
    }
    fetchMedicine();
  }, []);

  return (
    <div>
      <DataTables
        types="meds"
        dataList={meds}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          appendNewItem={appendNewMeds}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function SymptListTable(props) {
  const [sympt, setSympt] = React.useState([]);
  const EditData = (name) => {
    setSympt(name);
  };
  const appendNewSymt = (name) => {
    setSympt([...sympt, name]);
  };
  const deleteData = (itemId) => {
    setSympt(sympt.filter((item) => item.Id !== itemId));
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchSymptom() {
      let url = hostInfo + "/getsymtinfo";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data Symptoms data  ----11111111111111");
          // console.log(data);
          if (data != null) setSympt(data);
        });
    }
    fetchSymptom();
  }, []);

  return (
    <div>
      <DataTables
        types="symt"
        dataList={sympt}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          appendNewItem={appendNewSymt}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function DistListTable(props) {
  const [dis, setDis] = React.useState([]);
  const EditData = (name) => {
    setDis(name);
  };
  const appendNewDis = (name) => {
    setDis([...dis, name]);
  };
  const deleteData = (itemId) => {
    setDis(dis.filter((item) => item.Id !== itemId));
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchDisease() {
      let url = hostInfo + "/getdisinfo";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data Disease data  ----11111111111111");
          // console.log(data);
          if (data != null) setDis(data);
        });
    }
    fetchDisease();
  }, []);

  return (
    <div>
      <DataTables
        types="dise"
        dataList={dis}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          appendNewItem={appendNewDis}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function CenteredTabs() {
  const classes = useStyles();
  const tabs = ["reps", "meds", "symt", "dise"];
  const [values, setValues] = React.useState(tabs[0]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openDia, setOpenDia] = useState(false);

  // const [medSug, setMedSug] = useState([]);
  // const [repSug, setRepSug] = useState([]);
  // const [disSug, setDisSug] = useState([]);
  // const [symSug, setSymSug] = useState([]);
  // // const [drSug, setDrSug] = useState([]);

  // const appendNewMeds = (name) => {
  //   // Use the spread operator to create a new array with the appended value
  //   setMedSug([...medSug, name]);
  // };

  // const appendNewReps = (name) => {
  //   setRepSug([...repSug, name]);
  // };

  // const appendNewDis = (name) => {
  //   setDisSug([...disSug, name]);
  // };

  // const appendNewSymt = (name) => {
  //   setSymSug([...symSug, name]);
  // };

  // const appendNewDrs = (name) => {
  //   setDrSug([...drSug, name]);
  // };

  const handleDiaClickOpen = (e, value) => {
    setOpenDia(true);
    if (value === tabs[0]) {
      setValues(tabs[0]);
    } else if (value === tabs[1]) {
      setValues(tabs[1]);
    } else if (value === tabs[2]) {
      setValues(tabs[2]);
    } else if (value === tabs[3]) {
      setValues(tabs[3]);
    }
  };

  const handleDiaClickClose = () => {
    setOpenDia(false);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Reports List" {...a11yProps(0)} />
        <Tab label="Medicine List" {...a11yProps(1)} />
        <Tab label="Symptom List" {...a11yProps(2)} />
        <Tab label="Disease List" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ReactFileReader
          handleFiles={handleRepUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" className={classes.uploadBtn}>
            <img
              src={repIcon}
              width="20"
              height="20"
              alt="C"
              className={classes.iconCls}
            />
            Upload Report list
          </Button>
        </ReactFileReader>
        <Button
          value={tabs[0]}
          variant="outlined"
          style={{
            marginLeft: "240px",
            backgroundColor: "#5E92C2",
            marginTop: "-95px",
          }}
          onClick={(e) => handleDiaClickOpen(e, tabs[0])}
        >
          Add Report
        </Button>
        <ReportListTable
          types="report"
          values={values}
          open={openDia}
          // appendNewItem={appendNewReps}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ReactFileReader
          handleFiles={handleMedsUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" className={classes.uploadBtn}>
            <img
              src={medIcon}
              width="20"
              height="20"
              alt="C"
              className={classes.iconCls}
            />
            Upload Medicine list
          </Button>
        </ReactFileReader>
        <Button
          value={tabs[1]}
          variant="outlined"
          style={{
            marginLeft: "251px",
            backgroundColor: "#5E92C2",
            marginTop: "-95px",
          }}
          onClick={(e) => handleDiaClickOpen(e, tabs[1])}
        >
          Add Medicine
        </Button>
        <MedListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewMeds}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ReactFileReader
          handleFiles={handleSymptUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" className={classes.uploadBtn}>
            <img
              src={sympIcon}
              width="20"
              height="20"
              alt="C"
              className={classes.iconCls}
            />
            Upload Symptom List
          </Button>
        </ReactFileReader>
        <Button
          value={tabs[2]}
          variant="outlined"
          style={{
            marginLeft: "253px",
            backgroundColor: "#5E92C2",
            marginTop: "-95px",
          }}
          onClick={(e) => handleDiaClickOpen(e, tabs[2])}
        >
          Add Symptom
        </Button>
        <SymptListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewSymt}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ReactFileReader
          handleFiles={handleDesUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" className={classes.uploadBtn}>
            <img
              src={disIcon}
              width="20"
              height="20"
              alt="C"
              className={classes.iconCls}
            />
            Upload Disease List
          </Button>
        </ReactFileReader>
        <Button
          value={tabs[3]}
          variant="outlined"
          style={{
            marginLeft: "244px",
            backgroundColor: "#5E92C2",
            marginTop: "-95px",
          }}
          onClick={(e) => handleDiaClickOpen(e, tabs[3])}
        >
          Add Disease
        </Button>
        <DistListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewDis}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>

      {/* {openDia && (
        <AddReport
          values={values}
          open={openDia}
          appendNewItem={appendNewReps}
          handleClickClose={handleDiaClickClose} // Close the AddReport component
        />
      )} */}
      {/* {openDia && (
        <AddReport
          values={values}
          open={openDia}
          appendNewItem={appendNewMeds}
          handleClickClose={handleDiaClickClose} // Close the AddReport component
        />
      )}
      {openDia && (
        <AddReport
          values={values}
          open={openDia}
          appendNewItem={appendNewSymt}
          handleClickClose={handleDiaClickClose} // Close the AddReport component
        />
      )}
      {openDia && (
        <AddReport
          values={values}
          open={openDia}
          appendNewItem={appendNewDis}
          handleClickClose={handleDiaClickClose} // Close the AddReport component
        />
      )} */}
    </Paper>
  );
}

const MedicineMng = () => {
  // const [userlist, setUserlist] = useState([]);
  // const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <CSVReader
        onFileLoad={handleReadCSV}
        // inputRef={fileInput}
        style={{ display: "none" }}
      /> */}
      <CenteredTabs />

      {/* <div className={classes.content}></div>
      <div className={classes.content}>
        <ReactFileReader
          handleFiles={handleDrRefUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" color="primary">
            Upload Dr ref list
          </Button>
        </ReactFileReader>
      </div>
      <div className={classes.content}></div>
      <div className={classes.content}></div> */}
    </div>
  );
};

export default MedicineMng;
