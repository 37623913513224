import React, { Component } from "react";
import { wordGujMap } from "../../../../store";
import {
  Container,
  Grid,
  Card,
  Divider,
  CardContent,
  Table,
} from "@material-ui/core";
import moment from "moment";

function MedRender(props) {
  const { medList } = props;
  // console.log("Inside med render --------");
  // console.log(medList);
  if (medList && medList.filter((med) => med.medOut === true).length > 0) {
    return (
      <div className="table-responsive">
        <Table className="table-nowrap" style={{ fontSize: "30px" }}>
          {/* <thead>
            <tr>
              <th style={{ width: "70px" }}>No.</th>
              <th>Medicine</th>
              <th>Qnt-At</th>
              <th className="text-right">Count</th>
            </tr>
          </thead> */}
          {/* <th>Prescription</th> */}
          <tbody>
            {medList
              .filter((med) => med.medOut === true)
              .map((med, idx) => (
                <tr key={idx}>
                  {/* <td>{idx + 1}</td> */}
                  <td style={{ width: "35%" }} className="py-2">
                    {med.medName}
                  </td>
                  <td className="text-center">
                    ( {med.medCount ? "" + med.medCount : ""} ){"  "}
                    {/* {" "}
                    (--{med.medQnt ? "" + med.medQnt : ""}
                    
                    {wordGujMap[med.medSchd]
                      ? "---" + wordGujMap[med.medSchd]
                      : med.medSchd}{" "}
                    ) */}
                  </td>
                  <td className="text-right">
                    {" "}
                    {med.medQnt ? "" + med.medQnt : ""}
                  </td>
                  <td className="text-right">
                    {wordGujMap[med.medSchd]
                      ? "" + wordGujMap[med.medSchd]
                      : med.medSchd}{" "}
                  </td>

                  <td className="text-right">
                    {/* {med.medCount ? "..." + med.medCount : ""}
                    {"  "} */}
                    (
                    {wordGujMap[med.medTime]
                      ? wordGujMap[med.medTime]
                      : med.medTime}
                    )
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Divider style={{ marginTop: "10px" }} />
        <br />
      </div>
    );
  } else {
    return null;
  }
}

function RepRender(props) {
  const { repList } = props;

  // Filter out entries with non-empty repName
  const nonEmptyRepList = repList.filter((i) => i.repName !== "");

  if (repList.length > 0) {
    return (
      <div className="table-responsive" style={{ fontSize: "32px" }}>
        {nonEmptyRepList.length > 0 ? (
          <>
            <strong>Adv: </strong>
            {nonEmptyRepList.map((o) => o.repName).join(", ")}
            <Divider style={{ marginTop: "10px" }} />
          </>
        ) : null}

        <br />
      </div>
    );
  }
  return null;
}

function NoteRender(props) {
  const { notes } = props;
  if (notes.length > 0) {
    return (
      <div className="table-responsive" style={{ fontSize: "32px" }}>
        <th>Notes</th>
        {notes}
        <br />
        <Divider style={{ marginTop: "10px" }} />
        <br />
      </div>
    );
  }
  return null;
}

function RefRender(props) {
  const { refered, refInfo } = props;

  if (refered.length > 1) {
    return (
      <address style={{ fontSize: "32px" }}>
        <strong>Ref: </strong>
        {refered}
        <br />
        {refInfo}
        <br />
      </address>
    );
  } else {
    return null;
  }
}

// function PaymentInfo(props) {
//   const { total, paid } = props;

//   return (
//     <address>
//       <strong>Payment</strong>
//       <br />
//       Total: {total}
//       <br />
//       Paid Amount: {paid}
//     </address>
//   );
// }

// function NameInfo(props) {
//   const { firstname, lastname, address } = props;
//   return (
//     <address>
//       <strong>Name</strong>
//       <br />
//       {firstname} {lastname}
//       <br />
//       {address}
//     </address>
//   );
// }

class HtmlContentByInfo extends Component {
  render() {
    // const { selectedReportDetails, stateName, schoolName, startDate, endDate } =
    //   this.props;
    const { visit, user } = this.props;

    // console.log("Inside printing ----data is--");
    // console.log(visit);

    let medList =
      // visit.medicine_info ?
      JSON.parse(visit.medicine_info);
    // : [];
    // console.log(medList);
    let repList =
      // visit.report_info ?
      JSON.parse(visit.report_info);
    //  : [];
    const dateStr = moment(visit.date_time, "YYYY-MM-DDTHH:mm:ss").format(
      "DD-MM-YYYY"
    );
    // const dateStr = visit.date_time;

    return (
      <div
        id="pdf-element"
        style={{
          // maxWidth: "210mm",
          width: "90%",
          height: "100%",
          position: "relative",
          marginTop: "26%",
          marginLeft: "auto",
          marginRight: "auto",
          fontSize: "24px",
        }}
      >
        <Container fluid="true">
          <Grid container direction="row">
            <Grid item container direction="column">
              <Card>
                <CardContent>
                  <hr />
                  <Grid
                    container
                    direction="row"
                    style={{ padding: "0px 20px" }}
                  >
                    <Grid
                      item
                      xs
                      className=" text-left "
                      style={{ fontSize: "32px", paddingLeft: "25px" }}
                    >
                      {user.firstname} {user.lastname}
                    </Grid>
                    {/* <Grid item xs className=" text-left">
                      Age: {visit.age}
                    </Grid> */}
                    {/* <Grid item xs className=" text-right">
                      <address>
                        {dateStr}
                        <br />
                      </address>
                    </Grid> */}
                  </Grid>
                  <Divider
                    style={{ marginTop: "20px", marginBottom: "10px" }}
                  />
                  <Grid
                    container
                    direction="row"
                    style={{ padding: "0px 20px" }}
                  >
                    {/* <Grid item xs className=" text-left">
                      Patient Name: {user.firstname} {user.lastname}
                    </Grid> */}
                    {/* <Grid
                      item
                      xs
                      className=" text-left "
                      style={{ fontSize: "32px", paddingLeft: "20px" }}
                    >
                      {user.firstname} {user.lastname}
                    </Grid> */}
                    <Grid
                      item
                      xs
                      className=" text-left"
                      style={{ paddingLeft: "25px" }}
                    >
                      Age: {user.curr_age}
                    </Grid>
                    <Grid item xs className=" text-right">
                      <address>
                        {dateStr}
                        <br />
                      </address>
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: "20px" }} />
                  <MedRender medList={medList} />
                  <RepRender repList={repList} />
                  <NoteRender notes={visit.notes} />
                  <RefRender refered={visit.ref_dr} refInfo={visit.ref_info} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default HtmlContentByInfo;
