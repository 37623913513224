import React from "react";

import { Box, Grid, Button } from "@material-ui/core";

// import { Capture } from "./finger";
import { CaptureFinger, VerifyFinger } from "./mfs100";

export default function FingerScan(props) {
  var quality = 60; //(1 to 100) (recommanded minimum 55)
  var timeout = 10; // seconds (minimum=10(recommanded), maximum=60, unlimited=0 )

  const { handleFingerScan } = props;

  const Capture = async () => {
    //   const Capture = () => {
    // console.log("Inside capture function call .........");
    try {
      document.getElementById("imgFinger").src = "data:image/bmp;base64,";

      var res = await CaptureFinger(quality, timeout);
      // console.log("Finger scan Result -------");
      // console.log(res);

      if (res.httpStaus) {
        // res.data.ErrorCode & res.data.ErrorDescription;

        if (res.data.ErrorCode === "0") {
          document.getElementById("imgFinger").src =
            "data:image/bmp;base64," + res.data.BitmapData;
          handleFingerScan(res.data.IsoTemplate);
        }
      } else {
        alert(res.err);
      }
    } catch (e) {
      alert(e);
    }
    return false;
  };

  //   const GetPid = async () => {
  //     try {
  //       var isoTemplateFMR = document.getElementById("txtIsoTemplate").value;
  //       var isoImageFIR = document.getElementById("txtIsoImage").value;

  //       var Biometrics = Array(); // You can add here multiple FMR value
  //       Biometrics["0"] = new Biometric("FMR", isoTemplateFMR, "UNKNOWN", "", "");

  //       var res = GetPidData(Biometrics);
  //       if (res.httpStaus) {
  //         if (res.data.ErrorCode != "0") {
  //           alert(res.data.ErrorDescription);
  //         } else {
  //           document.getElementById("txtPid").value = res.data.PidData.Pid;
  //           document.getElementById("txtSessionKey").value =
  //             res.data.PidData.Sessionkey;
  //           document.getElementById("txtHmac").value = res.data.PidData.Hmac;
  //           document.getElementById("txtCi").value = res.data.PidData.Ci;
  //           document.getElementById("txtPidTs").value = res.data.PidData.PidTs;
  //         }
  //       } else {
  //         alert(res.err);
  //       }
  //     } catch (e) {
  //       alert(e);
  //     }
  //     return false;
  //   };
  // const Verify = async () => {
  //   // console.log("verify ----");

  //   var temp1 =
  //     "Rk1SACAyMAAAAADqAAABPAFiAMUAxQEAAAAoIkDVAKXKAEB8AOTRAEBnAIS5AEBYAOfPAIBRAPNJAECtAR9hAECdADyEAEBTAFCgAEDBATxtAEBfATFbAEC/ABN6AECmAA/5AEC9AH7TAICPAHW3AEDSAPdRAEDvAHRWAEBAAJS4AEDuAQlhAECMASlgAIDGATTuAEC2ACl+AEBUADZ2AEC+AVd5AEDZAJVUAICcAG7nAED2AJvRAICWAFWOAEBAANbLAIDQAR1kAED1ARvOAECbAT5wAED5AEPZAEC8AB7uAEDiAVB7AAAA";

  //   var temp2 =
  //     "Rk1SACAyMAAAAADkAAABPAFiAMUAxQEAAAAoIYDhAOjRAICKALHFAED9APxPAECBASjSAEEAASnOAEDyAGvZAECTAVLiAIDoAVFjAEDkAVZxAIBNAU0yAECiACZ7AICDAOTHAEDXARbPAIBkAPXFAEEVAMbSAEEJAIJaAIA7ALa5AIB3AGMEAECEAVFSAEDRAElqAIEOAE9gAEDZABrmAEDZAK1QAIDwAQpQAEDPASZTAEBRALu6AEC2AU1ZAECrAFflAEDQAVJyAEB2AUtSAEBKATStAEDdADniAECqABR2AAAA";

  //   try {
  //     //   var isotemplate = document.getElementById("txtIsoTemplate").value;
  //     var res = await VerifyFinger(temp1, temp2);

  //     if (res.httpStaus) {
  //       if (res.data.Status) {
  //         alert("Finger matched");
  //       } else {
  //         if (res.data.ErrorCode != "0") {
  //           alert(res.data.ErrorDescription);
  //         } else {
  //           alert("Finger not matched");
  //         }
  //       }
  //     } else {
  //       alert(res.err);
  //     }
  //   } catch (e) {
  //     alert(e);
  //   }
  //   return false;
  // };

  return (
    <div>
      <Grid
        container
        direction="row"
        // justifyContent="center"
        // alignItems="center"
      >
        <Grid item xs>
          <Box
            id="imgFinger"
            component="img"
            sx={{
              width: 145,
              height: 188,
              //   maxHeight: { xs: 233, md: 167 },
              //   maxWidth: { xs: 350, md: 250 },
              marginRight: 5,
              background: "#D6EFED",
              borderRadius: 3,
            }}
          />
        </Grid>
        <Grid item xs>
          {/* <img id="imgFinger" width="145px" height="188px" alt="Finger Image" /> */}

          <Button
            onClick={Capture}
            style={{
              background: "#FFCB42",
              borderRadius: 3,
            }}
          >
            Scan
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
