import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import IconButton from "@material-ui/core/IconButton";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import MainSearchPatient from "./MainSearchPatient";

// import PersonalDetail from "./ProfileInfo/PersonalDetail";
// import VisitList from "./ProfileInfo/VisitList";
// import PaymentHistory from "./ProfileInfo/PaymentHistory";
// import queryString from "query-string";
// import AddNewVisitForm from "./ProfileInfo/AddNewVisitForm";
// import { hostInfo } from "../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uploadBtn: {
    background: "#FF6085",
    borderRadius: 3,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#9BE27A",
    },
    // marginBottom: theme.spacing(2),
    // marginTop: theme.spacing(2),
  },
  iconCls: {
    marginRight: theme.spacing(1),
  },
}));

const MainSearch = () => {
  const classes = useStyles();
  // const [open, setOpen] = useState(false);

  const [searchOpen, setSearchOpen] = useState(false);

  const handleClickSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = (value) => {
    setSearchOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <IconButton
          // color="primary"
          aria-label="upload picture"
          component="span"
          onClick={handleClickSearchOpen}
        >
          <FingerprintIcon fontSize="medium" /> Search
        </IconButton>
        {searchOpen ? (
          <div>
            <MainSearchPatient open={searchOpen} closeHdl={handleSearchClose} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MainSearch;
