import React from "react";
import Chart from "react-apexcharts";
import { hostInfo } from "../../../../store";
// import ApexCharts from "ApexCharts";

class MonthlyGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          // height: 350,
          // stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            // columnWidth: "55%",
            // endingShape: "rounded",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        colors: ["#4F99E8", "#E8754F", "#B3E84F"],
        title: {
          text: "Monthly Revenue",
        },
        xaxis: {
          // type: "string",
          categories: [],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],

        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }
  async componentDidMount() {
    const amountTotal = [];
    const amountPaid = [];
    const amountRemain = [];
    const months = [];
    const getToken = () => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});
      return cookies.authToken;
    };

    const url = hostInfo + "/getmonthlyrevenue";

    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("Got data ---------------------##########");

        // this.medSug = data;
        if (data !== null) {
          // console.log("Got data length: ", data.length);
          // console.log(data);
          for (var i = 0; i < data.length; i++) {
            amountTotal.push(data[i]["total"]);
            amountPaid.push(data[i]["paid"]);
            amountRemain.push(data[i]["remain"]);
            months.push(data[i]["months"]);
            // console.log(data[i]);
          }
        }
        // console.log("Datas-----------");
        // console.log(days);
        // console.log(amountPaid);
        // console.log(amountRemain);

        this.setState({
          ...this.state,
          options: {
            chart: {
              id: "monthly-revenue",
            },

            zoom: {
              enabled: true,
            },
            xaxis: {
              // type: "string",
              categories: months,
            },
          },
          series: [
            {
              name: "Total",
              data: amountTotal,
            },
            {
              name: "Paid",
              data: amountPaid,
            },
            {
              name: "Remain",
              data: amountRemain,
            },
          ],
        });
      });
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        // width={800}
        height={400}
      />
    );
  }
}

export default MonthlyGraph;
