import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
// import { Chart } from "react-chartjs-2";
import { ThemeProvider } from "@material-ui/styles";
import { Auth0Provider } from "@auth0/auth0-react";
// import validate from "validate.js";

// import { chartjs } from "./helpers";
import theme from "./theme";
// import "react-perfect-scrollbar/dist/css/styles.css";
// import "./assets/scss/index.scss";
// import validators from "./common/validators";
import Routes from "./Routes";

const browserHistory = createBrowserHistory();

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          {/* <Auth0Provider
            domain="dev-ngybr5qehsy3mzez.us.auth0.com"
            clientId="3LxBWnbYrwivLiVWmUBFgzfPYk707IRP"
            redirectUri={window.location.origin + "/dashboard"}
            
          > */}
          <Routes />
          {/* </Auth0Provider> */}
        </Router>
      </ThemeProvider>
    );
  }
}
