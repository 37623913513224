import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Drawer } from "@material-ui/core";

import { Profile, SidebarNav } from "./components";
import MainSearch from "../../../../views/MainSearch";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 200,
    // elevation: 30,
    [theme.breakpoints.up("sm")]: {
      marginTop: 35,
      height: "calc(100% - 40px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // padding: theme.spacing(1),
  },

  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, sidebarVal, ...rest } = props;

  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      {/* <Paper elevation={3}> */}
      <div {...rest} className={clsx(classes.root, className)}>
        {/* <Profile />
        <Divider className={classes.divider} /> */}
        {/* <MainSearch /> */}
        <Divider className={classes.divider} />

        <SidebarNav
          className={classes.nav}
          // pages={pages}
          sidebarVal={sidebarVal}
        />

        {/* <UpgradePlan /> */}
      </div>
      {/* </Paper> */}
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
