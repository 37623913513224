import React from "react";
import Chart from "react-apexcharts";
import { hostInfo } from "../../../../store";
// import ApexCharts from "ApexCharts";

class DailyGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          // height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        title: {
          text: "Daily Revenue",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        // colors: ["#F07FB5", "#F0B47F"],
        xaxis: {
          type: "datetime",
          categories: [],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  async componentDidMount() {
    const amountTotal = [];
    const amountPaid = [];
    const amountRemain = [];
    const days = [];
    const getToken = () => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});
      return cookies.authToken;
    };

    let url = hostInfo + "/getdailyrevenue";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("Got data -----------11111111111111");
        if (data !== null) {
          // console.log("Got data length: ", data.length);
          // console.log(data);
          // this.medSug = data;
          for (var i = 0; i < data.length; i++) {
            amountTotal.push(data[i]["total"]);
            amountPaid.push(data[i]["paid"]);
            amountRemain.push(data[i]["remain"]);
            days.push(data[i]["days"] + " GMT");
            // console.log(data[i]);
          }
        }

        this.setState({
          ...this.state,
          options: {
            chart: {
              id: "daily-revenue",
            },
            zoom: {
              enabled: true,
            },
            xaxis: {
              type: "datetime",
              categories: days,
            },
          },
          series: [
            {
              name: "Remain",
              data: amountRemain,
            },
            {
              name: "Paid",
              data: amountPaid,
            },
          ],
        });
      });
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        // width={800}
        height={400}
      />
    );
  }
}

export default DailyGraph;
