import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import PatientVisitList from "./PatientVisitList";
import { hostInfo } from "../../../../store";

const VisitListDia = (props) => {
  const {
    isOpen = false,
    onClose = () => {},
    title = "My Dialog",
    patientId = 0,
  } = props;

  const [visitlist, setVisitlist] = useState([]);
  // console.log("Dialog file -------------");
  // console.log(patientId);

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    if (patientId === 0) {
      // console.log("Patient id is -- 0");
      return;
    }
    // console.log("Patient id is --" + patientId);
    let url = hostInfo + "/getpatientallvisit?patient_id=" + patientId;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }).then((response) => {
      // console.log("inside 1");
      if (response.ok) {
        // console.log("inside 1");
        response.json().then((json) => {
          // console.log("setting the response ---");
          if (json != null) {
            setVisitlist(json);
          } else {
            console.log(json);
          }
        });
      }
    });
    // .then((response) => response.json())
    // .then((response) => {
    //   setVisitlist(response);
    // });
  }, [patientId]);
  // console.log("Visit list for patient_id: ", patientId);
  // console.log(visitlist);

  return (
    // <Dialog onClose={onClose} open={isOpen}>
    //   <DialogTitle>{title}</DialogTitle>
    //   <PatientVisitList visitlist={visitlist} />
    // </Dialog>

    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={true}
      maxWidth={"lg"}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
          Last 3 months data...
        </DialogContentText> */}
        <PatientVisitList visitlist={visitlist} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VisitListDia;
