import React, { Fragment } from "react";

import { withStyles } from "@material-ui/styles";
import { Button, FormControlLabel } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { hostInfo } from "../../../../store";

import FormGroup from "@material-ui/core/FormGroup";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ToggleSwitch from "../../../../common/toggleBtn";

import countryStateCity from "country-state-city";
import NumberFormat from "react-number-format";
// import { ICountry, IState, ICity } from "country-state-city";
import { genderArr } from "../../../../store";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: 250,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 250,
  },

  countryControl: {
    marginRight: theme.spacing(1),
    width: 165,
  },
  toggleSwitch: {
    marginTop: theme.spacing(3),
  },
  location: {
    direction: "row",
    alignItems: "spce-between",
  },
  addressControl: {
    minWidth: "95%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
});

// const genderArr = [
//   {
//     value: "M",
//     label: "Male",
//   },
//   {
//     value: "F",
//     label: "Female",
//   },
//   {
//     value: "O",
//     label: "Other",
//   },
// ];

export default withStyles(styles)(
  class NewPatient extends React.Component {
    state = {
      open: false,

      formdetail: {
        username: "",
        lastname: "",
        email: "",
        gender: "",
        age: "",
        disability: false,
        country: "101",
        state: "12",
        city: "1041",
        mobile: "",
        address: "",

        usernameError: false,
        lastnameError: false,
        emailError: false,
        ageError: false,
        countryError: false,
        stateError: false,
        cityError: false,

        usernameErrorTxt: "",
        lastnameErrorTxt: "",
        emailErrorTxt: "",
        ageErrorTxt: "",
        disabilityErrorTxt: "",
        countryErrorTxt: "",
        stateErrorTxt: "",
        cityErrorTxt: "",
      },
    };
    countryArr = countryStateCity.getAllCountries();
    stateArr = countryStateCity.getStatesOfCountry("101");
    cityArr = countryStateCity.getCitiesOfState("12");
    selectedCountry = "India";
    selectedState = "Gujarat";
    selectedCity = "Surat";
    stateDisabled = true;
    cityDisabled = true;

    validate = () => {
      let isError = false;
      const { formdetail } = this.state;

      // Clean old error status
      formdetail.usernameError = false;
      formdetail.lastnameError = false;
      formdetail.emailError = false;
      formdetail.countryError = false;
      formdetail.stateError = false;
      formdetail.cityError = false;
      formdetail.ageError = false;
      formdetail.disabilityError = false;

      formdetail.usernameErrorTxt = "";
      formdetail.lastnameErrorTxt = "";
      formdetail.emailErrorTxt = "";
      formdetail.countryErrorTxt = "";
      formdetail.stateErrorTxt = "";
      formdetail.cityErrorTxt = "";
      formdetail.genderErrorTxt = "";
      formdetail.ageErrorTxt = "";

      // Username Validation----
      // 1. Length
      // 2. special character check
      if (formdetail.username.length < 2) {
        isError = true;
        formdetail.usernameError = true;
        formdetail.usernameErrorTxt =
          "Username needs to be atleast 2 characters long";
      }

      // Lastname Validation
      if (formdetail.lastname.length < 2) {
        isError = true;
        formdetail.lastnameError = true;
        formdetail.lastnameErrorTxt =
          "Lastname needs to be atleast 2 characters long";
      }

      // Email Validation
      // 1. @ check
      if (formdetail.email.length < 1) {
        formdetail.email = "-";
      }

      // Mobile
      if (formdetail.mobile.length < 1) {
        formdetail.mobile = "-";
      }

      // console.log(
      //   "Selected C: " +
      //     this.selectedCountry +
      //     "S: " +
      //     this.selectedState +
      //     "city: " +
      //     this.selectedCity
      // );
      // console.log(this.cityArr);
      // Country, state, city validation
      if (!this.selectedCountry.length) {
        // console.log("contry vaidation --------");
        // console.log(this.selectedCountry);
        // console.log(this.countryArr);
        isError = true;
        formdetail.countryError = true;
        formdetail.countryErrorTxt = "Invalid Country";
      }
      if (false === this.validateInArry(this.stateArr, this.selectedState)) {
        // console.log("State vaidation --------");
        // console.log(this.selectedState);
        // console.log(this.stateArr);
        // console.log(this.cityArr);
        isError = true;
        formdetail.stateError = true;
        formdetail.stateErrorTxt = "Invalid State";
      }

      // City
      if (false === this.validateInArry(this.cityArr, this.selectedCity)) {
        // console.log("city arry len: " + this.cityArr.length);
        // console.log(this.selectedCity);
        // console.log(this.cityArr.indexOf[this.selectedCity]);
        isError = true;
        formdetail.cityError = true;
        formdetail.cityErrorTxt = "Invalid City";
      }

      this.setState({
        ...this.state.formdetail,
      });

      return isError;
    };

    handleSubmit = () => {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      const err = this.validate();
      // const err = false;
      if (!err) {
        // On submit form success
        let { formdetail } = this.state;
        let formObj = {
          firstname: formdetail.username,
          lastname: formdetail.lastname,
          mail_id: formdetail.email,
          gender: formdetail.gender,
          age: formdetail.age,
          // disability: formdetail.disability,
          city: this.selectedCity,
          state: this.selectedState,
          country: this.selectedCountry,
          address:
            formdetail.address +
            ", " +
            this.selectedCity +
            ", " +
            this.selectedState +
            ", " +
            this.selectedCountry,
          mobile: formdetail.mobile,
        };
        // console.log("Final from object...");
        // console.log(JSON.stringify(formObj));
        let url = hostInfo + "/adduser";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(formObj),
        });

        this.props.closeHdl();
        window.location.reload();
        return true;
      }
      //Error in Submit..............
      // console.log("Validation failed, Error in Submit--------");
      return false;
    };

    handleClickOpen = () => {
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleNumericChange = (name, val) => {
      // console.log(name);
      // console.log(val);
      const value = val.floatValue;
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value },
      });
    };

    handleOnChange = (e, name) => {
      // console.log("On Change log.......");
      // console.log(name);
      // console.log(e.target.value);

      const value = e.target.value;
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value },
      });
    };

    handleGenChange = (e, name, value) => {
      // console.log("Inside handle Gen change change---");
      // console.log(id);
      // console.log(name);
      // console.log(value.label);

      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value.label },
      });
    };

    mask = "/[1-4]/g";
    handleCountryChange = (name, value) => {
      // console.log("In handle country change");
      // console.log(name);
      // console.log(value);
      this.selectedCountry = value.name;
      this.setState({
        formdetail: {
          ...this.state.formdetail,
          [name]: value.id,
        },
      });
      // console.log("Latest country id : " + value.id);

      this.stateArr = countryStateCity.getStatesOfCountry(value.id);
      this.stateDisabled = false;
      // console.log(this.stateArr);
    };

    handleStateChange = (name, value) => {
      this.selectedState = value.name;

      // console.log(name);
      // console.log(value);
      this.setState({
        formdetail: {
          ...this.state.formdetail,
          [name]: value.id,
        },
      });

      // console.log("Latest state id : " + value.id);
      this.cityArr = countryStateCity.getCitiesOfState(value.id);
      // console.log("city array length..: " + this.cityArr.length);
      if (!this.cityArr.length) {
        this.selectedCity = "NA";
        this.cityDisabled = true;
      } else {
        this.cityDisabled = false;
        // console.log(this.cityArr);
      }
    };

    searchFromArray(myArray, id) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          return myArray[i].name;
        }
      }
      return " ";
    }
    validateInArry(myArray, nameKey) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].name === nameKey) {
          return true;
        }
      }
      return false;
    }

    handleDisability = (name, value) => {
      // console.log(name);
      // console.log(value.target.checked);

      this.setState({
        formdetail: {
          ...this.state.formdetail,
          [name]: value.target.checked,
        },
      });
    };

    handleCityChange = (name, value) => {
      this.selectedCity = value.name;
      // console.log(value);
      // console.log("name: " + name + " id: " + value.id);
      this.setState({
        formdetail: {
          ...this.state.formdetail,
          [name]: value.id,
        },
      });
    };

    render() {
      const {
        // open,
        formdetail: {
          username,
          lastname,
          email,
          age,
          mobile,
          address,

          usernameError,
          lastnameError,
          emailError,
          countryError,
          stateError,
          cityError,
          mobileError,
          addressError,

          usernameErrorTxt,
          lastnameErrorTxt,
          emailErrorTxt,
          countryErrorTxt,
          stateErrorTxt,
          cityErrorTxt,
          mobileErrorTxt,
          addressErrorTxt,
        },
      } = this.state;
      const { countryArr, stateArr, cityArr } = this;
      // const {  } = this.props;
      // console.log(this.countryArr);
      // console.log(this.cityArr);
      const { classes, closeHdl, open } = this.props;
      return (
        // <div>
        <Fragment>
          <Dialog
            open={open}
            onClose={closeHdl}
            // aria-labelledby="Add User"
          >
            <DialogTitle id="NewPatient">Patient Profile</DialogTitle>
            <DialogContent>
              <DialogContentText>Fill the patient details</DialogContentText>
              <form className={classes.root} autoComplete="off">
                <TextField
                  //////////////////  First Name
                  autoFocus
                  margin="dense"
                  id="username"
                  error={usernameError}
                  value={username}
                  onChange={(e) => {
                    this.handleOnChange(e, "username");
                  }}
                  label="Username"
                  type="username"
                  helperText={usernameErrorTxt}
                />
                <TextField
                  //////////////////  Last Name
                  // autoFocus
                  margin="dense"
                  id="lastname"
                  error={lastnameError}
                  value={lastname}
                  onChange={(e) => {
                    this.handleOnChange(e, "lastname");
                  }}
                  label="Lastname"
                  type="lastname"
                  helperText={lastnameErrorTxt}
                />
                <Autocomplete
                  id="gender"
                  margin="dense"
                  classes={{ option: classes.autocomp }}
                  // autoFocus
                  disableClearable
                  options={genderArr}
                  getOptionLabel={(option) => option.label}
                  // onChange={(e) => {
                  //   this.handleOnChange(e, "gender");
                  // }}
                  onChange={(e, value) => {
                    this.handleGenChange(e, "gender", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Gender"
                      name="gender"
                      margin="dense"
                      InputProps={{
                        ...params.InputProps,
                        // autoComplete: "new-password",
                      }}
                    />
                  )}
                />

                {/* <FormControl className={classes.formControl}>
                  <InputLabel id="gender">Gender</InputLabel>
                  <Select
                    autoFocus
                    error={genderError}
                    value={gender}
                    onChange={(e) => {
                      this.handleOnChange(e, "gender");
                    }}
                    type="gender"
                  >
                    {genderArr.map((gender) => (
                      <option key={gender.value} value={gender.value}>
                        {gender.label}
                      </option>
                    ))}
                  </Select>
                  <FormHelperText>{genderErrorTxt}</FormHelperText>
                </FormControl> */}

                <TextField
                  //////////////////// Email
                  // autoFocus
                  id="email"
                  error={emailError}
                  value={email}
                  onChange={(e) => {
                    this.handleOnChange(e, "email");
                  }}
                  label="Email"
                  type="email"
                  helperText={emailErrorTxt}
                />

                <NumberFormat
                  className={classes.formControl}
                  // mask="_"
                  id="age"
                  label="Age"
                  value={age}
                  // postfix="Yrs"
                  format="####"
                  customInput={TextField}
                  name="age"
                  onValueChange={(value) =>
                    this.handleNumericChange("age", value)
                  }
                />

                <TextField
                  ///////////////////////// Mobile
                  className={classes.formControl}
                  // autoFocus
                  id="mobile"
                  error={mobileError}
                  value={mobile}
                  onChange={(e) => {
                    this.handleOnChange(e, "mobile");
                  }}
                  label="Mobile"
                  type="mobile"
                  helperText={mobileErrorTxt}
                />

                <FormControlLabel
                  className={classes.toggleSwitch}
                  control={
                    <ToggleSwitch
                      onClick={(e) => this.handleDisability("disability", e)}
                      name="disability"
                      title="Disability"
                      leftVal="No"
                      rightVal="Yes"
                    />
                  }
                />

                <TextField
                  //////////////////  Address
                  className={classes.addressControl}
                  // autoFocus
                  margin="dense"
                  id="Address"
                  error={addressError}
                  value={address}
                  onChange={(e) => {
                    this.handleOnChange(e, "address");
                  }}
                  label="Address"
                  type="address"
                  // fullWidth
                  helperText={addressErrorTxt}
                />
                <FormGroup row={true}>
                  <Autocomplete
                    className={classes.location}
                    id="country"
                    classes={{ option: classes.autocomp }}
                    // autoFocus
                    disableClearable
                    disabled={true}
                    defaultValue={countryArr.find((v) => v.id === "101")}
                    options={countryArr}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      this.handleCountryChange("country", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.countryControl}
                        defaultValue="India"
                        margin="normal"
                        variant="outlined"
                        error={countryError}
                        helperText={countryErrorTxt}
                        InputProps={{
                          ...params.InputProps,

                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />

                  <Autocomplete
                    //////////////////// State
                    freeSolo
                    id="state"
                    classes={{ option: classes.autocomp }}
                    className={classes.location}
                    // autoFocus
                    disableClearable
                    includeInputInList
                    defaultValue={stateArr.find((v) => v.id === "12")} // Gujarat
                    options={stateArr}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      this.handleStateChange("state", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.countryControl}
                        {...params}
                        label="State"
                        margin="normal"
                        error={stateError}
                        variant="outlined"
                        defaultValue="Guj"
                        helperText={stateErrorTxt}
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    //////////////////// City
                    freeSolo
                    id="city"
                    classes={{ option: classes.autocomp }}
                    // autoFocus
                    className={classes.location}
                    disableClearable
                    includeInputInList
                    defaultValue={cityArr.find((v) => v.id === "1041")}
                    options={cityArr}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      this.handleCityChange("city", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.countryControl}
                        {...params}
                        label="City"
                        error={cityError}
                        variant="outlined"
                        helperText={cityErrorTxt}
                        margin="normal"
                        InputProps={{
                          ...params.InputProps,
                          // type: "search",
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.handleSubmit();
                }}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
        // </div>
      );
    }
  }
);
