import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Grid, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    fontSize: 12,
  },
  fieldSpace: {
    "& > *": {
      margin: theme.spacing(1),
      // marginLeft: theme.spacing(3),
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  tableHeader: { textAlign: "center" },
  formField: {
    margin: theme.spacing(3),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 250,
  },
  countryControl: {
    // margin: theme.spacing(1),
    minWidth: 150,
  },
  repField: {
    marginRight: theme.spacing(1),
    width: "50%",
  },
  addressControl: {
    minWidth: "95%",
  },
  spceBetw: {
    marginRight: theme.spacing(1),
  },
  fabBut: {
    marginRight: theme.spacing(1),
    // minWidth: "8%",
  },
  addBut: {
    background: "#00C39B",
    "&:hover": {
      background: "#9BE27A",
    },
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
}));

const EditVisitReport = (props) => {
  const classes = useStyles();
  return (props.repList || []).map((val, idx) => {
    return (
      <div className={classes.fieldSpace} key={val.index}>
        <Grid
          container
          direction="row"
          // justify="space-between"
          // className={classes.medName}
          className={classes.spceBetw}
          // alignItems="center"
        >
          <Grid className={classes.repField}>
            <Autocomplete
              id={idx.toString()}
              margin="dense"
              classes={{ option: classes.autocomp }}
              // autoFocus
              autoSelect
              freeSolo
              disableClearable
              options={props.repSug}
              value={val.repName}
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) =>
                option === value || value === ""
              }
              onChange={(e, value) => {
                props.handleChange(idx, "repName", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Reports"
                  margin="dense"
                  InputProps={{
                    ...params.InputProps,
                    // autoComplete: "new-password",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid className={classes.fabBut}>
            {/* <TextField
          autoFocus
          margin="dense"
          id={idx.toString()}
          data-id={idx}
          name="repName"
          label="Report"
          type="repName"
          className={classes.repField}
        /> */}
            {idx === props.repList.length - 1 ? (
              <Fab
                color="primary"
                aria-label="add"
                size="small"
                onClick={() => props.add()}
                className={classes.addBut}
              >
                <AddIcon />
              </Fab>
            ) : (
              <Fab
                color="inherit"
                aria-label="add"
                size="small"
                onClick={() => props.delete(val.index)}
              >
                <RemoveIcon />
              </Fab>
            )}
          </Grid>
        </Grid>
      </div>
    );
  });
};
export default EditVisitReport;
