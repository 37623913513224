import React from "react";
import { makeStyles } from "@material-ui/styles";

import { VisitsMain } from "./components";
// import mockData from "./data";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
    background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
  },
  inner: {
    minWidth: "100%",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const ProductList = () => {
  const classes = useStyles();

  // const [products] = useState(mockData);

  return (
    <div className={classes.root}>
      <VisitsMain classes={classes} />
    </div>
  );
};

export default ProductList;
