import React, { Fragment } from "react";

import { withStyles } from "@material-ui/styles";
import {
  Button,
  Divider,
  Checkbox,
  Grid,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { hostInfo } from "../../../store";
// import FuncComp from "./testFunc";

import AddKwDia from "./AddKwDia";
import FormMedicine from "./components/Medicine";
import FormReport from "./components/Report";
// import RemoveIcon from "@material-ui/icons/Remove";

import FormGroup from "@material-ui/core/FormGroup";
import { visitFormfields } from "../../../store";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import PrintHtml from "./PrintHtml/PrintHtml";
// import HtmlContentByInfo from "./PrintHtml/HtmlContentByInfo";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      // width: 230,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  saveBtn: {
    background: "#94B49F",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 230,
  },
  // countryControl: {
  //   // margin: theme.spacing(1),
  //   minWidth: 150,
  // },
  countryControl: {
    marginRight: theme.spacing(1),
    minWidth: 170,
  },
  insurance: {
    // width: 230,
    marginTop: theme.spacing(2),
  },
  bloodGroupFld: {
    minWidth: 150,
  },
  seprator: {
    margin: theme.spacing(2),
  },
  seprator1: {
    marginTop: theme.spacing(2),
  },
  refCls: {
    color: "#D46D91",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  seprator2: {
    width: "100%",
    ...theme.typography.body2,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  multilineFld: {
    display: "flex",
    flexWrap: "wrap",
  },
  amountGreen: {
    borderColor: "pink",
  },
  specialOutline: {
    borderColor: "pink",
    //borderWidth: 4
  },
  presc: {
    width: "100%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
  cardClr: {
    background: "#F3F8F8",
  },
  freeTextNotes: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

var countryStateCity = require("country-state-city").default;

export default withStyles(styles)(
  class AddNewVisitForm extends React.Component {
    state = {
      visit: {},
      isSubmit: false,
      refMark: false,
      refDisabled: true,
      refGrpDisabled: true,
      open: false,
      openDia: false,
      panelExpand: false,
      userList: [],
      drList: [],
      formdetail: {
        userId: "",
        drId: "",
        bloodGroup: "",
        insurance: false,
        weight: 0,
        height: 0,
        bp: "",
        pulse: 0,
        spo: 0,
        temperature: 0,
        age: 0,
        ptNotes: [],
        drNotes: [],
        notes: "",
        diaNotes: "",
        amount: 0,
        amountPaid: 0,
        drRef: null,
        drGrp: null,
        drRefInfo: null,
        medList: [
          {
            index: Math.random(),
            // medType: "",
            medName: "",
            medQnt: 1,
            medSchd: "",
            medTime: "After Eat",
            medCount: 0,
            medOut: false,
          },
        ],
        repList: [
          {
            index: Math.random(),
            repName: "",
          },
        ],

        userIdError: false,
        drIdError: false,
        bloodGroupError: false,
        insuranceError: false,
        weightError: false,
        heightError: false,
        ageError: false,
        bpError: false,
        spoError: false,
        temperatureError: false,
        pulseError: false,
        userIdErrorTxt: "",
        drIdErrorTxt: "",
        bloodGroupErrorTxt: "",
        insuranceErrorTxt: "",
        weightErrorTxt: "",
        bpErrorTxt: "",
        spoErrorTxt: "",
        temperatureErrorTxt: "",
        pulseErrorTxt: "",
        heightErrorTxt: "",
        ageErrorTxt: "",

        amountClass: "",
      },
    };

    countryArr = countryStateCity.getAllCountries();
    stateArr = [];
    cityArr = [];
    selectedCountry = "";
    selectedState = "";
    selectedCity = "";
    stateDisabled = true;
    cityDisabled = true;
    medSug = [];
    repSug = [];
    drSug = [];
    symSug = [];
    disSug = [];
    drGroups = [];
    isUserIdPresentInArray(myArray, id) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          // console.log("User id present in list-----------");
          return true;
        }
      }
      // console.log("User id not present in list");
      return false;
    }

    isDrIdPresentInArray(myArray, id) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          // console.log("Doctor id is present in list--------");
          return true;
        }
      }
      // console.log("Doctor id not present in list");
      return false;
    }

    validate = () => {
      let isError = false;
      const { formdetail } = this.state;

      // console.log(this.state);

      // Clean old error status
      formdetail.userIdError = false;
      formdetail.drIdError = false;

      formdetail.userIdErrorTxt = "";
      formdetail.drIdErrorTxt = "";

      // console.log()

      // User id Validation----    Future Use...
      // 1. Length
      // 2. valid id number
      // if (
      //   !this.isUserIdPresentInArray(this.state.userList, formdetail.userId)
      // ) {
      //   isError = true;
      //   formdetail.userIdError = true;
      //   formdetail.userIdErrorTxt =
      //     "Invalid user. Please select proper username";
      // }

      // console.log(
      //   "userid: " +
      //     formdetail.userId +
      //     " and length: " +
      //     formdetail.userId.length
      // );

      // Doctor id Validation----  Future use....
      // 1. Length
      // 2. special character check
      // if (!this.isDrIdPresentInArray(this.state.drList, formdetail.drId)) {
      //   isError = true;
      //   formdetail.drIdError = true;
      //   formdetail.drIdErrorTxt = "Invalid doctor. Please select proper doctor";
      // }
      // console.log(
      //   "drid: " + formdetail.drId + " and length: " + formdetail.drId.length
      // );

      // this.setState({
      //   ...this.state.formdetail,
      // });

      return isError;
    };

    handleSubmit = (e) => {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      e.preventDefault();
      // const err = this.validate();

      // if (err) {
      //   console.log("Get error in submit validation...");
      //   return;
      // }
      // On submit form success
      let { formdetail } = this.state;

      // console.log("In Submit ----");
      // console.log(formdetail);
      let medList = formdetail.medList.filter(
        (x, idx) => x.medName !== "" || idx === 0
      );
      let repList = formdetail.repList.filter(
        (x, idx) => x.repName !== "" || idx === 0
      );

      let formObj = {
        user_id: parseInt(this.props.patient_id),
        // refDr: formdetail.drId, // Correct as per ref Dr :TODO
        //  bloodGroup: "",
        mediclaim: formdetail.insurance ? 1 : 0,
        disease: "none",
        weight: formdetail.weight,
        bp: formdetail.bp,
        spo: formdetail.spo,
        pulse: formdetail.pulse,
        temperature: formdetail.temperature,
        height: formdetail.height,
        age: formdetail.age,
        meds: JSON.stringify(medList),
        reps: JSON.stringify(repList),
        amount: formdetail.amount,
        paid_amount: formdetail.amountPaid,
        pt_notes: JSON.stringify(formdetail.ptNotes),
        dr_notes: JSON.stringify(formdetail.drNotes),
        notes: formdetail.notes,
        dia_notes: formdetail.diaNotes,
        ref_dr:
          this.state.refMark && formdetail.drRef && formdetail.drRef.length
            ? formdetail.drRef
            : "-",
        ref_grp:
          this.state.refMark && formdetail.drGrp && formdetail.drGrp.length
            ? formdetail.drGrp
            : "-",
        ref_info:
          this.state.refMark &&
          formdetail.drRef &&
          // formdetail.drGrp &&
          formdetail.drRef.length
            ? formdetail.drRefInfo
            : "-",
        is_ref: this.state.refMark ? 1 : 0,
      };

      // console.log("Final from object...");
      this.setState({
        ...this.state,
        isSubmit: true,
        visit: formObj,
      });
      // console.log(formObj);
      let url = hostInfo + "/addcasevisit";
      // console.log(url);
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(formObj),
      });
      // .then((response) => response.json())
      // .then((data) => {
      //   console.log("Success:", data);
      // })
      // .catch((error) => {
      //   console.error("Error:", error);
      // });

      // console.log(this.state);
      // console.log(this.state.visit);
      this.handleClose();
      window.location.reload();
      return true;

      // //Error in Submit..............
      // console.log("Validation failed, Error in Submit--------");
      // return false;
    };

    componentDidMount() {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      // console.log("Component DID MOUNT!");
      let url = hostInfo + "/getmeds";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in meds ... ");
          // console.log(data);
          if (data != null) this.medSug = data;
        });

      // repSug -------------------
      url = hostInfo + "/getreps";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in reps ... ");
          // console.log(data);
          if (data != null) this.repSug = data;
        });

      // drSug ------------------
      url = hostInfo + "/getdrs";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in drs ... ");
          // console.log(data);
          if (data != null) this.drSug = data;
        });

      // disSug ------------------
      url = hostInfo + "/getdis";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in dis ... ");
          // console.log(data);
          if (data != null) this.disSug = data;
        });

      // symSug ------------------
      url = hostInfo + "/getsymt";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in sym ... ");
          // console.log(data);
          if (data != null) this.symSug = data;
        });

      // drGroup ------------------
      url = hostInfo + "/getdrgroups";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in sym ... ");
          // console.log(data);
          if (data != null) this.drGroups = data;
        });
    }

    appendNewMeds = (name) => {
      // console.log("Appending Meds ...: ", name);
      this.medSug.push(name);
    };
    appendNewReps = (name) => {
      // console.log("Appending Reps ...: ", name);
      this.repSug.push(name);
    };
    appendNewDis = (name) => {
      // console.log("Appending Dis ...: ", name);
      this.disSug.push(name);
    };
    appendNewSymt = (name) => {
      // console.log("Appending Symt ...: ", name);
      this.symSug.push(name);
    };
    appendNewDrs = (name) => {
      // console.log("Appending Drs ...: ", name);
      this.drSug.push(name);
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleDiaClickOpen = () => {
      this.setState({ openDia: true });
    };

    handleDiaClickClose = () => {
      this.setState({ openDia: false });
    };

    handleChangeCheckbox = (event) => {
      // console.log(event.target.checked);
      if (event.target.checked === true) {
        this.setState({ refGrpDisabled: false, refMark: true });
      } else {
        this.setState({
          refGrpDisabled: true,
          refMark: false,
        });
        let formdetail = { ...this.state.formdetail };

        formdetail.drGrp = null;
        formdetail.drRef = null;
        formdetail.drRefInfo = null;
        this.setState({ formdetail });
        // console.log(formdetail);
      }
      // console.log(this.state);
      // setChecked(event.target.checked);
      // handleChange(idx, "medOut", event.target.checked);
    };

    handleOnChange = (e, name) => {
      const value = e.target.value;
      // console.log("Value change --- ");
      // console.log(e.target);
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMultiFld = (name, value) => {
      // console.log("------ Inside Multifiled handler ------");
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMedChange = (id, name, value) => {
      // console.log("Inside handleMed change---");
      // console.log(id);
      // console.log(name);
      // console.log(value);

      let medList = [...this.state.formdetail.medList];
      medList[id][name] = value;

      this.setState({ medList });
    };

    handleRepChange = (id, name, value) => {
      // console.log("Inside Rep change---");
      // console.log(id);
      // console.log(name);
      // console.log(value);

      let repList = [...this.state.formdetail.repList];
      repList[id][name] = value;

      this.setState({ repList });
    };

    handleDrGrpChange = (e, value) => {
      // console.log("Inside handle dr Group change change---");
      // console.log(id);
      // console.log(e.target);
      // console.log(value);
      let formdetail = { ...this.state.formdetail };
      formdetail.drGrp = value && value.name;
      formdetail.drRef = null;
      formdetail.drRefInfo = null;
      this.setState({ formdetail });
      // this.setState({
      //   ...this.state,
      //   formdetail: {
      //     ...this.state.formdetail,
      //     drRef: null,
      //   },
      // });
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      if (value.id > 0) {
        this.setState({ refDisabled: false });
        // drGroup ------------------
        let url = hostInfo + "/getdrdetails?group_id=" + value.id;
        fetch(url, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log("Data dr details from db ... ");
            // console.log(data);

            if (data != null) this.setState({ drList: data });
            else this.setState({ drList: [] });
          });
      } else {
        this.setState({ refDisabled: true });
      }
      // this.setState({
      //   ...this.state,
      //   formdetail: {
      //     ...this.state.formdetail,
      //     [name]: value,
      //   },
      // });
    };

    handleDrChange = (e, name, value) => {
      // console.log("Inside handle dr change change------");
      // console.log(e.target);
      // console.log(name);
      // console.log(value.name);

      let formdetail = { ...this.state.formdetail };
      formdetail.drRef = value && value.name;
      formdetail.drRefInfo = value.description + " (" + value.mobile + ")";
      this.setState({ formdetail });

      // this.setState({
      //   ...this.state,
      //   formdetail: {
      //     ...this.state.formdetail,
      //     [name]: value.na,
      //   },
      // });
      // console.log(this.state);
    };

    handleInsurance = (name, value) => {
      // console.log(name);
      // console.log(value.target.checked);

      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value.target.checked,
        },
      });
    };

    // Mitesh: TODO make index incremental
    addMedRow = (e) => {
      this.setState((prevState) => ({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          medList: [
            ...prevState.formdetail.medList,
            {
              index: Math.random(),
              medName: "",
              // medType: "",
              medQnt: 1,
              medSchd: "",
              medTime: "After Eat",
              medCount: 0,
              medOut: false,
            },
          ],
        },
      }));
    };

    addRepRow = (e) => {
      this.setState((prevState) => ({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          repList: [
            ...prevState.formdetail.repList,
            {
              index: Math.random(),
              repName: "",
            },
          ],
        },
      }));
    };

    deleteMedRow = (index) => {
      // console.log("Inside Delete med row.......");
      // console.log(this.state.formdetail.medList);
      // console.log(index);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          medList: this.state.formdetail.medList.filter(
            (s, idx) => index !== s.index
          ),
        },
      });
      // const taskList1 = [...this.state.taskList];
      // taskList1.splice(index, 1);
      // this.setState({ taskList: taskList1 });
    };

    deleteRepRow = (index) => {
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          repList: this.state.formdetail.repList.filter(
            (s, idx) => index !== s.index
          ),
        },
      });
      // const taskList1 = [...this.state.taskList];
      // taskList1.splice(index, 1);
      // this.setState({ taskList: taskList1 });
    };

    handleChange = (e) => {
      // console.log("Inside handle change..11.....");
      // console.log(e);
      // console.log(e.target.name);
      // console.log(e.target.value);
      // console.log(e.target.type);
      // console.log(e.target.checked);
      // console.log(e.target.id);

      if (
        ["medName", "medSchd", "medTime", "repName"].includes(e.target.name)
      ) {
        return;
      } else if (["medCount", "medOut", "medQnt"].includes(e.target.name)) {
        let medList = [...this.state.formdetail.medList];

        medList[e.target.id][e.target.name] =
          e.target.type === "number"
            ? parseInt(e.target.value)
            : e.target.value;
        this.setState({ medList });

        // } else if (["repName"].includes(e.target.name)) {
        //   let repList = [...this.state.formdetail.repList];
        //   repList[e.target.id][e.target.name] = e.target.value;
        //   this.setState({ repList });
        // } else if (e.target.type === "checkbox") {
        //   let medList = [...this.state.formdetail.medList];
        //   medList[e.target.id][e.target.name] = e.target.checked;
        //   this.setState({ medList });
      } else {
        this.setState({
          formdetail: {
            ...this.state.formdetail,
            [e.target.name]:
              e.target.type === "number"
                ? parseFloat(e.target.value)
                : e.target.value,
          },
        });
      }
    };

    render() {
      const {
        // drList,
        // isSubmit,
        // visit,
        formdetail: { medList, repList, drRef, drGrp },
      } = this.state;

      // console.log(this.state.isSubmit);
      // console.log(this.state.visit);
      // const { countryArr, stateArr, cityArr } = this;
      // // console.log(this.countryArr);
      // console.log("blood group----");
      // console.log(bloodGroups);
      // console.log("blood group End----");
      const { classes } = this.props;
      //const { handleSubmit, register, errors } = useForm();
      // console.log("len-----first time--" + this.cityArr.length);
      // console.log(this.state);
      // console.log("Got patient name from parent: " + patient);

      return (
        <div>
          <AddKwDia
            open={this.state.openDia}
            appendNewMeds={this.appendNewMeds}
            appendNewReps={this.appendNewReps}
            appendNewDis={this.appendNewDis}
            appendNewSymt={this.appendNewSymt}
            appendNewDrs={this.appendNewDrs}
            // keywordData={this.keywordData}
            handleClickOpen={this.handleDiaClickOpen}
            handleClickClose={this.handleDiaClickClose}
            // handleClickAdd={this.handleDiaClickAdd}
          />

          <Fragment>
            <form
              className={classes.root}
              autoComplete="off"
              onChange={this.handleChange}
            >
              {/* 
              // Mitesh: Optional as per Dr Request
              <TextField
                //////////////////  Patient
                disabled
                margin="normal"
                id="patient"
                name="patient"
                value={patient}
                label="Patient"
                type="strint"
              /> */}

              <FormGroup row={true}>
                <div>
                  <TextField
                    //////////////////  weight
                    autoFocus
                    margin="dense"
                    id="weight"
                    name="weight"
                    pattern="[0-9]*"
                    label="Weight"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-2">
                  <TextField
                    //////////////////  height
                    // autoFocus
                    margin="dense"
                    id="height"
                    name="height"
                    pattern="[0-9]*"
                    label="Height"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-2">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="spo"
                    name="spo"
                    pattern="[0-9]*"
                    label="SpO2"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                {/* <div className="ml-2">
                  <TextField
                    //////////////////  age
                    // autoFocus
                    margin="dense"
                    id="age"
                    name="age"
                    pattern="[0-9]*"
                    label="Age"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div> */}
              </FormGroup>

              <FormGroup row={true}>
                <div>
                  <TextField
                    margin="dense"
                    placeholder="120/80"
                    id="bp"
                    name="bp"
                    label="BP"
                    type="text"
                    style={{ width: "180px" }}
                    // value={this.state.bp}
                    // onChange={this.handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm of Hg</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-2">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="pulse"
                    style={{ width: "180px" }}
                    name="pulse"
                    pattern="[0-9]*"
                    label="Pulse"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/ min</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div className="ml-2">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="temperature"
                    name="temperature"
                    pattern="[0-9]*"
                    style={{ width: "180px" }}
                    label="Temperature"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">°F</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <div>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                      {/* <div> */}
                      <Autocomplete
                        //////////////////// Multifield
                        multiple
                        id="multi field"
                        options={this.symSug}
                        filterSelectedOptions
                        size="small"
                        freeSolo
                        autoHighlight={true}
                        classes={{ option: classes.autocomp }}
                        onChange={(e, newValue) => {
                          this.handleMultiFld("ptNotes", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={visitFormfields.complain}
                            // placeholder="ptNotes"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                      {/* </div> */}
                    </Grid>
                    <Grid item xs>
                      {/* <div> */}
                      <Autocomplete
                        //////////////////// Multifield
                        multiple
                        id="multi field"
                        // autoFocus
                        // disableClearable
                        size="small"
                        freeSolo
                        classes={{ option: classes.autocomp }}
                        options={this.disSug}
                        filterSelectedOptions
                        onChange={(e, newValue) => {
                          this.handleMultiFld("drNotes", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // multiline
                            // rows={2}
                            variant="outlined"
                            label={visitFormfields.diagnose}
                            // placeholder="drNotes"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <div>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs>
                        <TextField
                          // autoFocus
                          id="outlined-basic"
                          className={classes.freeTextNotes}
                          multiline
                          rows={2}
                          label={visitFormfields.notes}
                          placeholder={visitFormfields.notes}
                          name="notes"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          // autoFocus
                          id="outlined-basic"
                          className={classes.freeTextNotes}
                          multiline
                          rows={2}
                          label={visitFormfields.diaNotes}
                          placeholder={visitFormfields.diaNotes}
                          name="diaNotes"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <FormGroup>
                  <div>
                    <Typography gutterBottom variant="h5" color="textSecondary">
                      Prescriptions
                    </Typography>
                    <Divider variant="middle" />

                    <FormMedicine
                      add={this.addMedRow}
                      delete={this.deleteMedRow}
                      medList={medList}
                      handleChange={this.handleMedChange}
                      medSug={this.medSug}
                    />

                    <FormReport
                      add={this.addRepRow}
                      delete={this.deleteRepRow}
                      repList={repList}
                      handleChange={this.handleRepChange}
                      repSug={this.repSug}
                    />
                  </div>
                  <Divider variant="middle" className={classes.seprator} />
                </FormGroup>
                <div>
                  <Grid
                    container
                    direction="row"
                    // justify="space-between"
                    spacing={2}
                  >
                    <Grid item xs={3}>
                      <TextField
                        // autoFocus
                        label="Amount"
                        id="amount"
                        name="amount"
                        defaultValue="0"
                        pattern="[0-9]*"
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        // autoFocus
                        label="Amount Paid"
                        id="amountPaid"
                        name="amountPaid"
                        defaultValue="0"
                        variant="outlined"
                        type="number"
                        pattern="[0-9]*"
                        inputProps={{
                          classes: {
                            classes: { notchedOutline: classes.specialOutline },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.seprator1}>
                  {/* <Card className={classes.cardClr}> */}
                  {/* <Card>
                    <CardContent> */}
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={3}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        className={classes.refCls}
                      >
                        Refer
                        <Checkbox
                          onChange={(e) => this.handleChangeCheckbox(e)}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        id="drGrp"
                        margin="dense"
                        value={
                          this.state.refMark === false
                            ? null
                            : drGrp
                            ? { name: drGrp }
                            : null
                        }
                        classes={{ option: classes.autocomp }}
                        disableClearable
                        disabled={this.state.refGrpDisabled}
                        options={this.drGroups}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                          this.handleDrGrpChange(e, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Dr Grp"
                            // name="drGrp"
                            margin="dense"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        id="drRef"
                        margin="dense"
                        classes={{ option: classes.autocomp }}
                        value={
                          this.state.refMark === false
                            ? null
                            : drRef
                            ? { name: drRef }
                            : null
                        }
                        disableClearable
                        disabled={this.state.refDisabled || !this.state.refMark}
                        options={this.state.drList}
                        // getOptionLabel={(option) => {
                        //   if (option.mobile && option.description) {
                        //     return `${option.name} | ${option.mobile} | ${option.description}`;
                        //   } else if (option.mobile) {
                        //     return `${option.name} | ${option.mobile} `;
                        //   } else if (option.description) {
                        //     return `${option.name} | ${option.description}`;
                        //   } else {
                        //     return option.name;
                        //   }
                        // }}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                          value.name === "" || option.name === value.name
                        }
                        onChange={(e, value) => {
                          this.handleDrChange(e, "drRef", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Dr Reference"
                            // name="drRef"
                            margin="dense"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {/* </CardContent>
                  </Card> */}
                </div>
              </FormGroup>

              <div>
                <Button
                  onClick={(e) => {
                    this.handleSubmit(e);
                  }}
                  className={classes.saveBtn}
                  variant="outlined"
                >
                  Save
                </Button>
                {/* <Button
                  style={{ marginLeft: "20px" }}
                  onClick={(e) => {
                    this.handleSubmit(e);
                  }}
                  className={classes.saveBtn}
                  variant="outlined"
                >
                  Save & Print
                  <FuncComp isSubmit={isSubmit} visit={visit} />
                </Button> */}
                {/* {this.state.isSubmit ? (
                    <RemoveIcon />
                  ) : (
                    // <HtmlContentByInfo visit={this.state.data} />
                    {}
                  )} */}
                {/* <PrintHtml visit={visit} classes={classes} /> */}
              </div>
            </form>
          </Fragment>
        </div>
      );
    }
  }
);
