import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ThemeConsumer } from "styled-components";

const useStyles = makeStyles({
  root: {
    // height: 200,
  },
  card: {
    height: 200,
    // backgroundColor: "#C3E0E5",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 16,
  },
  valueCls: {
    fontSize: 60,
  },
  pos: {
    marginBottom: 5,
  },
});

export default function DashboardCards(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const { title, value, color, unit } = props;

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {unit}
        </Typography>
        <Typography
          className={classes.valueCls}
          style={{
            paddingTop: 60,
            color: color,
          }}
          align="center"
          variant="h5"
          component="h2"
        >
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}
