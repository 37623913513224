import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  // CardHeader,
  Col,
  Row,
  Container,
} from "react-bootstrap";
import { Avatar } from "@material-ui/core";

const gender = { M: "Male", F: "Female" };
const useStyles = makeStyles((theme) => ({
  remain: {
    color: "#fff",
    width: "100%",
    // maxWidth: "100px",
    background: "#E8625F",
  },
  containerValue: {
    wordWrap: "break-word",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
const PersonalDetail = (props) => {
  const { user } = props;
  const classes = useStyles();
  // const tmp = Math.floor(Math.random() * 14 + 1);
  // console.log("user details in args..........");

  return (
    <Card small="true" className="mb-4 pt-0">
      <Card.Header
        className="border-bottom text-center"
        // style={{ backgroundImage: `url(${backImg})` }}
        style={{
          background: "#EEF9DE",
          // backgroundSize: "100% 10px",
        }}
      >
        <Container>
          <Row>
            <div className=" mx-auto ">
              <img
                className="rounded-circle"
                src={
                  user.gender === "M"
                    ? require(`./../../../images/avatar/m9.jpg`)
                    : require(`./../../../images/avatar/f8.jpg`)
                }
                alt={user.firstname}
                width="110"
              ></img>
            </div>
          </Row>
          <Row>
            <h4 className="mb-1 mx-auto ">
              {user.firstname} {user.lastname}
            </h4>
          </Row>
          <Row>
            <span className="text-muted d-block mb-0 mx-auto">
              {user.gender ? gender[user.gender] : "No Gender"} |{" "}
              {user.curr_age ? user.curr_age : 0} Yrs | {user.mobile}
            </span>
          </Row>
          <Row>
            <span className="d-block mx-auto">{user.mail_id}</span>
          </Row>
          <Row>
            <div className={classes.containerValue}>
              <Avatar
                variant="rounded"
                className={classes.remain}
                style={{ padding: "0px 8px", fontSize: "18px" }}
              >
                {user.illness}
              </Avatar>
            </div>
          </Row>
        </Container>
      </Card.Header>
    </Card>
  );
};

PersonalDetail.propTypes = {
  /**
   * The user details object.
   */
  PersonalDetail: PropTypes.object,
};

PersonalDetail.defaultProps = {};

export default PersonalDetail;
