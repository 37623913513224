import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import { useMediaQuery } from "@material-ui/core";
import { sidebarVal } from "../../store";

import { Sidebar, Topbar } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 50,
    },
  },

  topbar: {
    background:
      "radial-gradient( circle farthest-corner at 3.8% 11.2%, rgba(89,162,162,1)  0%, rgba(153,215,184,61) 90.2% )",
    height: 50,
  },
  shiftContent: {
    paddingLeft: 200,
  },
  content: {
    height: "auto",
    width: "auto",
    // background: "#e6e5e4",
    margin: theme.spacing(1),
    // [theme.breakpoints.up("lg")]: {
    //   marginTop: -15,
    //   // height: "calc(100% - 50px)",
    // },

    // marginTop: theme.spacing(-2),
    // minHeight: "100%",
  },
  scrn: {
    // background: "#e6e5e4",
    // height: "full",
  },
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();
  // const theme = useTheme();
  // const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
  //   defaultMatches: true,
  // });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = true;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: true, //Mitesh
        [classes.scrn]: true,
      })}
    >
      <Topbar className={classes.topbar} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={"persistent"}
        sidebarVal={sidebarVal}
      />
      <main className={classes.content}>
        {children}
        {/* <Footer /> */}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
