import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { hostInfo } from "../../../store";
import { saveAs } from "file-saver";
import Moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const getToken = () => {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {});
  return cookies.authToken;
};

async function handleRestore() {
  // console.log("Inside backup now....");
  let url = hostInfo + "/getbackup";
  let filename = "drApp-bkp-" + Moment().format("DD-MM-YYYY--HH-MM") + ".sql";
  await fetch(url, {
    // mode: "no-cors",
    headers: {
      "Content-Type": "text/plain",

      Authorization: `Bearer ${getToken()}`,

      Connection: "keep-alive",
      Accept: "*/*",
    },
  })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, filename));
}

export default function Restore() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button variant="contained" color="primary" onClick={handleRestore}>
        Restore
      </Button>
    </div>
  );
}
