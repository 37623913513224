import React from "react";
// import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Divider, Box } from "@material-ui/core";
import { hostInfo } from "../../store";

import { useEffect, useState } from "react";

import AssForm from "./AssForm";
// import StatReports from "./components";
// import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    // backgroundColor: "green",
  },
  title: {
    // marginTop: theme.spacing(2),
    margin: theme.spacing(1),
  },
  maintitle: {
    // marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },
}));

const Assessment = () => {
  const classes = useStyles();
  // const [dashboardCard, setDashboardCard] = useState({});
  const [isAssReport, setIsAssReport] = useState(false);
  // const [formData, setFormData] = useState({
  //   start: new Date(),
  //   end: new Date(),
  // });
  // console.log([dashboardCard].map((i) => i.total_ptn));

  // const validateDateLimit = (start, end) => {
  //   var st = parseInt(moment(start, "DD-MM-YYYY").valueOf() / 1000);
  //   var en = parseInt(moment(end, "DD-MM-YYYY").valueOf() / 1000);
  //   console.log(st);
  //   // var ms = moment(start, "DD-MM-YYYY").diff(moment(end, "DD-MM-YYYY"));
  //   // console.log("Validation... Diff---", ms);
  //   // console.log("Validation...", st, "---", en);

  //   if (st > en) {
  //     // console.log("start time is greater than end time...");
  //     return -1;
  //   }
  //   return 0;
  // };

  // const handleSubmitAss = (start, end) => {
  //   // console.log("In Submit....");
  //   // console.log(start);
  //   // console.log(end);

  //   var err = validateDateLimit(start, end);
  //   if (err) {
  //     alert("Invalid input");
  //     // console.log("Got validation errror -------");
  //     return;
  //   }

  //   var st = start + " 00:00:00";
  //   var en = end + " 23:59:59";
  //   setIsAssReport(true);
  // };

  // const handleChange = (name, value) => {
  //   // console.log(name);
  //   // console.log(value);
  //   if (name == "start") {
  //     setFormData({ ...formData, start: [value] });
  //   } else if (name == "end") {
  //     setFormData({ ...formData, end: [value] });
  //   }
  //   console.log(formData);
  // };
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    let url = hostInfo + "/getdashboardcard";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res != null) {
          if (res != null);
        }
      });
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Typography
          className={classes.maintitle}
          variant="h3"
          gutterBottom
          color="textSecondary"
        >
          Assesment
        </Typography>
        <Divider />
        <Box
          boxShadow={2}
          borderRadius={5}
          bgcolor="background.paper"
          // m={1}
          p={1}
        >
          <Typography
            className={classes.title}
            variant="h4"
            gutterBottom
            color="textSecondary"
          >
            Filters
          </Typography>
          <Divider />
          <div>
            <AssForm
              // handleSubmitAss={handleSubmitAss}
              // formData={formData}
              setIsAssReport={setIsAssReport}
            />
          </div>

          {/* <div>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={handleSubmitAss(formData)}
            >
              Submit
            </Button>
          </div> */}
          {/* <Grid container spacing={2}></Grid> */}
        </Box>
        {/* {isAssReport ? (
          <Box
            boxShadow={2}
            borderRadius={5}
            bgcolor="background.paper"
            mt={3}
            p={1}
          >
            <StatReports isAssReport={isAssReport} />
          </Box>
        ) : null} */}
      </div>
    </>
  );
};

export default Assessment;
