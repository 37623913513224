import React from "react";
import Chart from "react-apexcharts";

class TotalUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "apexchart-example",
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },

      series: [
        {
          name: "Series 1",
          data: [
            {
              x: "W1",
              y: 22,
            },
            {
              x: "W2",
              y: 29,
            },
            {
              x: "W3",
              y: 13,
            },
            {
              x: "W4",
              y: 32,
            },
          ],
        },
        {
          name: "Series 2",
          data: [
            {
              x: "W1",
              y: 43,
            },
            {
              x: "W2",
              y: 43,
            },
            {
              x: "W3",
              y: 43,
            },
            {
              x: "W4",
              y: 43,
            },
          ],
        },
      ],
    };
  }
  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="heatmap"
        // width={500}
        // height={320}
      />
    );
  }
}

export default TotalUsers;
