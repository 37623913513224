import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Box, Typography } from "@material-ui/core";

import PersonalDetail from "./ProfileInfo/PersonalDetail";
import VisitList from "./ProfileInfo/VisitList";
import PaymentHistory from "./ProfileInfo/PaymentHistory";
import queryString from "query-string";
import AddNewVisitForm from "./ProfileInfo/AddNewVisitForm";
import { hostInfo } from "../../store";
// import PdfGen from "../PdfGen";

const PatientProfile = (props) => {
  const { history } = props;
  const [user, setUser] = useState([]);
  // const [openDia, setOpenDia] = useState(false);

  let search_id = history.location.search; //  here... search_id is a patient_id
  if (!search_id) {
    window.location = "/dashboard";
  }

  const values = queryString.parse(search_id);
  const patient_id = values.patient_id;
  // console.log("Inside patien ------------------ patirnt id: ", patient_id);

  // const handleDiaClickOpen = () => {
  //   setOpenDia(true);
  // };

  // const handleDiaClickClose = () => {
  //   setOpenDia(false);
  // };
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    // console.log("Fetching patient profile data for patient id: " + patient_id);
    let url = hostInfo + "/getpatientbyid?patient_id=" + patient_id;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // setTimeout(() => setStory(data), 1500);
        // console.log("Success ", data);
        // if (!Object.entries(data).length) {
        //   window.location = "/dashboard";
        // }
        if (data != null) setUser(data);
      });
    // .catch((error) => {
    //   console.log("Error", error);
    // });
  }, [patient_id]);

  // useEffect(() => {
  //   console.log("Fetching patient profile data for patient id: " + patient_id);
  //   let url = "http://" + hostInfo + "/getpatientbyid?patient_id=" + patient_id;
  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((response) => {
  //       setUser(response);
  //       console.log(response);
  //       if (!Object.entries(response).length) {
  //         window.location = "/dashboard";
  //       }
  //     });
  // }, []);

  // console.log("Fetched user details..........");
  // console.log(user);

  return (
    <Container fluid className="main-content-container px-3">
      <Row noGutters className="page-header py-2">
        <Typography
          gutterBottom
          variant="h4"
          component="h2"
          color="textSecondary"
        >
          Patient Profile
        </Typography>
      </Row>
      <Row>
        <Col lg="5" style={{ paddingRight: 0, paddingLeft: 0 }}>
          <div>
            <PersonalDetail patient_id={patient_id} user={user} />
          </div>
          <div>
            {/* <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="textSecondary"
            >
              Payment History
            </Typography> */}
            <PaymentHistory patient_id={patient_id} />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="textSecondary"
              className="page-header pt-4"
            >
              Consult History
            </Typography>
            <VisitList patient_id={patient_id} user={user} />
          </div>
        </Col>

        <Col lg="7" style={{ paddingRight: 0 }}>
          {/* <Box
            boxShadow={3}
            borderRadius={5}
            bgcolor="background.paper"
            m={1}
            p={1}
          >
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <AddKyDia
                open={openDia}
                handleClickOpen={handleDiaClickOpen}
                handleClickClose={handleDiaClickClose}
              />
            </ButtonGroup>
          </Box> */}
          <Box
            boxShadow={3}
            borderRadius={5}
            bgcolor="background.paper"
            // m={1}
            p={1}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="textSecondary"
            >
              Add Visit Details
            </Typography>
            <AddNewVisitForm
              user={user}
              patient_id={patient_id}
              patient={user.firstname + " " + user.lastname}
            />
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

export default PatientProfile;
