import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EditVisit from "./EditVisit";
import { visitFormfields } from "../../../store";

// import { PdfGen } from "../..";

import {
  Box,
  Grid,
  Button,
  Table,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
  TableRow,
} from "@material-ui/core";
// import PrintHtml from "./PrintHtml/PrintHtml";

// const mediclaim = [{ 0: "No" }, { 1: "Yes" }];

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(1),
    fontSize: 12,
  },
  expandGrid: {
    minWidth: "100%",
  },
  medRowOut: {
    color: "#8AA65C",
    fontWeight: "bold",
    fontSize: "12px",
  },
  medRowIn: {
    color: "#282E29",
    fontSize: "12px",
  },
  prescription: {
    // width: "50%",
    margin: theme.spacing(1),
  },
  reports: {
    Width: "20%",
    margin: theme.spacing(1),
  },
  saveBtn: {
    background: "#94B49F",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
    margin: theme.spacing(2),
  },
}));

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

const VisitDetailInfo = (props) => {
  const { visit, handleClickDelete } = props;
  // console.log(visit);
  const [openDelDia, setOpenDelDia] = useState(false);
  const [openEditDia, setOpenEditDia] = useState(false);
  // const [openPrint, setOpenPrint] = useState(false);

  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const classes = useStyles();

  const handleClickDelOpen = () => {
    setOpenDelDia(true);
  };

  const handleClickEditOpen = () => {
    setOpenEditDia(true);
  };

  // const handleClickPrint = () => {
  //   setOpenEditDia(true);
  // };

  const handleClose = () => {
    setOpenDelDia(false);
    setOpenEditDia(false);
  };

  // const handleOpenPrint = (val) => {
  //   setOpenPrint(val);
  // };

  // function handleEditDiaClose() {
  //   setOpenEditDia(false);
  // }

  const handleDelClose = () => {
    handleClickDelete(visit.id);
    setOpenDelDia(false);
  };

  const handleSubmit = () => {
    // console.log("Added for Submit");
    setOpenEditDia(false);
    window.location.reload();
  };

  // console.log("Inside visit detail info--------");
  // console.log(visit);
  let medList =
    // visit.medicine_info ?
    JSON.parse(visit.medicine_info);
  // : [];
  // console.log(medList);
  // console.log(medList);
  let repList =
    // visit.report_info ?
    JSON.parse(visit.report_info);
  // : [];
  return (
    <div className={classes.expandGrid}>
      {/* <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <div className={classes.prescription}>
            <Typography color="textPrimary">
              {visitFormfields.complain}
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "12px" }}>
              {visit.patient_notes.length
                ? JSON.parse(visit.patient_notes).join(", ")
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs>
          <div className={classes.prescription}>
            <Typography color="textPrimary">
              {visitFormfields.diagnose}
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "12px" }}>
              {visit.dr_notes.length
                ? JSON.parse(visit.dr_notes).join(", ")
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid> */}
      <Grid container direction="row">
        <Grid item xs>
          <div className={classes.prescription}>
            <Typography color="textPrimary">{visitFormfields.notes}</Typography>
            <Typography color="textSecondary" style={{ fontSize: "12px" }}>
              {visit.notes ? visit.notes : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs>
          <div className={classes.prescription}>
            <Typography color="textPrimary">
              {visitFormfields.diaNotes}
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "12px" }}>
              {visit.dia_notes ? visit.dia_notes : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item xs>
          <Box borderLeft={5} className={classes.prescription}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="Prescription" fontSize={"8"}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Prescription</TableCell>
                    <TableCell align="center">Dose-At</TableCell>
                    <TableCell align="center">when</TableCell>
                    <TableCell align="right">#</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medList.map((med, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        align="left"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medName ? med.medName : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {"medQnt" in med ? med.medQnt : ""}{" "}
                        {med.medSchd ? med.medSchd : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medTime ? med.medTime : "-"}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medCount ? med.medCount : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs>
          <Box borderLeft={5} className={classes.reports}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Reports</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {repList.map((rep, idx) => (
                    <TableRow key={idx} style={{ fontSize: "12px" }}>
                      <TableCell align="left">
                        {rep.repName ? rep.repName : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs>
          <Button
            variant="contained"
            size="small"
            // color={"#FDD692"  }
            className={classes.saveBtn}
            startIcon={<DeleteIcon />}
            onClick={handleClickDelOpen}
          >
            Delete
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            size="small"
            // color={"#FDD692"  }
            className={classes.saveBtn}
            startIcon={<EditIcon />}
            onClick={handleClickEditOpen}
          >
            Edit
          </Button>
        </Grid>
        {/* <Grid item xs>
          <PrintHtml visit={visit} classes={classes} />
        </Grid> */}
        <Dialog
          open={openEditDia}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Edit Details</DialogTitle>
          <DialogContent>
            <EditVisit
              visit={visit}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={openDelDia}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delte Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              CANCEL
            </Button>
            <Button onClick={handleDelClose} color="primary" autoFocus>
              DELETE IT
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
};

VisitDetailInfo.propTypes = {
  className: PropTypes.string,
  // caselist: PropTypes.array.isRequired,
};

export default VisitDetailInfo;
