import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListIcon from "@material-ui/icons/List";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import VisitListDia from "./diaComponent/VisitListDia";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";

// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";

import { hostInfo } from "../../../store";

import { Box, Avatar, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // paddin: spacing(2),
  },
  inactive: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    // color: "secondary",
    marginRight: theme.spacing(2),
  },
  active: {
    background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    // color: "secondary",

    marginRight: theme.spacing(2),
  },
  subVisit: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  infoIcon: {
    color: "#EE5007",
    // backgroundColor: "black",
    fontSize: 30,
  },
}));

const VisitsMain = (props) => {
  // const { classes } = props;
  const [patientList, setPatientList] = useState([]);
  // const [data, setData] = useState(rawData);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [patientId, setPatientId] = useState(0);
  // const [dialogWord, setDialogWord] = useState('');
  // const [dialogId, setDialogId] = useState('');
  const classes = useStyles();

  const handleDialogClose = (event) => {
    setIsDialogOpen(false);
  };

  // const handleId = event => {
  //   setDialogId(event.target.value);
  // }

  // const handleWord = event => {
  //   setDialogWord(event.target.value);
  // }

  // const handleAddNewRow = event => {
  //   if (!dialogId || !dialogWord) return;

  //   setData(
  //     // Here you can add the new row to whatever index you want
  //     [{ id: dialogId, word: dialogWord }, ...data]
  //   );
  // }

  const columns = [
    {
      title: "Name",
      field: "name",
      searchable: true,

      render: (rowData) => (
        <div className={classes.containerIcon}>
          <Avatar
            variant="rounded"
            className={`${rowData.status ? classes.active : classes.inactive}`}
          >
            {rowData.case_no}
          </Avatar>

          <Box fontSize={16} fontFamily="Helvetica" fontWeight={550}>
            {rowData.name}
          </Box>
        </div>
      ),
    },
    {
      title: "Gender",
      field: "gender",
      lookup: { M: "Male", F: "Female", O: "Other" },
    },
    { title: "Current Age", field: "age", searchable: false },
    // { title: "Mail Id", field: "email" },
    {
      title: "Phone",
      field: "phone",
      // type: "numeric",
      searchable: true,
      // align: "center",
    },
    {
      title: "Total",
      field: "total",
      // type: "numeric",
      searchable: false,
      // align: "center",
    },
    {
      title: "Paid",
      field: "paid",
      // type: "numeric",
      searchable: false,
      // align: "center",
    },
    {
      title: "Remain",
      field: "remain",
      // type: "numeric",
      searchable: false,
      // sorting: true,
      // align: "center",
      render: (rowData) => (
        <div>
          <Chip
            label={rowData.remain}
            color="primary"
            style={{
              backgroundColor:
                rowData.remain == 0
                  ? "#D1D1D1"
                  : rowData.remain > 0
                  ? "#FFA1A1"
                  : "#B2C8DF",
            }}
          />
        </div>
      ),
    },
  ];
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    let url = hostInfo + "/getptlistinfo";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res != null) {
          if (res != null) setPatientList(res);
        }
      });
  }, []);

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Fragment>
        <MaterialTable
          title="Patients & Visits"
          columns={columns}
          data={patientList.map((patient) => ({
            name: patient.firstname + " " + patient.lastname,
            gender: patient.gender,
            age: patient.curr_age + " Yrs",
            // disease: patient.disease_name,
            phone: patient.mobile,
            id: patient.id,
            // email: patient.mail_id,
            total: patient.total,
            paid: patient.paid,
            remain: patient.remain,
          }))}
          actions={[
            {
              icon: () => (
                <FormatListBulletedIcon className={classes.infoIcon} />
              ),
              // icon: "edit",
              onClick: (event, rowData) => {
                // console.log("Inside table on click ----------");
                // console.log(rowData.id);
                setIsDialogOpen(true);
                setPatientId(rowData.id);
              },

              tooltip: "Detail info",
            },
            // Mitesh: Commenting because Delete option not needed. It is already present in patients managment
            // {
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) => {
            //     const url =
            //       "http://" +
            //       hostInfo +
            //       "/deletePatient?patient_id=" +
            //       rowData.id;
            //     if (window.confirm("You want to delete " + rowData.name)) {
            //       fetch(url, {
            //         method: "POST",
            //       })
            //         .then(() => {
            //           setPatientList(
            //             patientList.filter(
            //               (patient) => patient.id !== rowData.id
            //             )
            //           );
            //           // console.log("Vist " + rowData.id + " Deleted Successfully");
            //         })
            //         .catch((error) => {
            //           console.error("There was an error!", error);
            //         });
            //     }
            //   },
            // },
          ]}
          options={{
            headerStyle: {
              backgroundColor: "#AFE1CE",
              color: "#000",
            },
            sorting: true,

            minBodyHeight: "auto",

            actionsColumnIndex: -1,
          }}
        />
        {isDialogOpen ? (
          <VisitListDia
            title="Visit Details"
            isOpen={isDialogOpen}
            onClose={handleDialogClose}
            patientId={patientId}
          />
        ) : null}
      </Fragment>
    </div>
  );
};

export default VisitsMain;
