import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";
import { medSchd } from "../../../../store";
import { medSchdTime } from "../../../../store";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  // Card,
  Grid,
  // Box,
  // Paper,
  // CardActions,
  // Container,
  // CardContent,
  // Avatar,
  // Checkbox,
  Tooltip,
  TextField,
  // Table,
  // TableBody,
  // TableCell,
  // TableHead,
  // TableRow,
  // Typography,
  // TablePagination,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    fontSize: 12,
  },
  fieldSpace: {
    "& > *": {
      margin: theme.spacing(1),
      // marginLeft: theme.spacing(2),
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    // marginTop: theme.spacing(1),
  },
  tableHeader: { textAlign: "center" },
  formField: {
    // margin: theme.spacing(),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 250,
  },
  countryControl: {
    // margin: theme.spacing(1),
    minWidth: 150,
  },
  medName: {
    marginRight: theme.spacing(1),
    width: "30%",
  },
  // medQnt: {
  //   marginRight: theme.spacing(1),
  //   width: "30",
  // },
  medSchd: {
    marginRight: theme.spacing(1),
    minWidth: "10%",
  },
  medSchdTime: {
    marginRight: theme.spacing(1),
    minWidth: "14%",
  },
  medCountField: {
    marginRight: theme.spacing(1),
    width: "7%",
  },
  fabBut: {
    // marginRight: theme.spacing(1),
    minWidth: "6%",
  },
  addBut: {
    background: "#00C39B",
    "&:hover": {
      background: "#9BE27A",
    },
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
}));

const VisitFormMedicine = (props) => {
  const classes = useStyles();
  // console.log("medicine list inside med component");
  // console.log(props.medList);
  const { handleChange } = props;
  // const [selectedValue, setSelectedValue] = React.useState(medSchdTime[0]);
  const [checked, setChecked] = React.useState(false);
  const handleChangeCheckbox = (event, idx) => {
    setChecked(event.target.checked);
    handleChange(idx, "medOut", event.target.checked);
  };

  return (props.medList || []).map((val, idx) => {
    // console.log("index---------" + idx);
    // console.log("menOut --- " + val.medOut);

    return (
      <div className={classes.fieldSpace} key={val.index}>
        <Grid
          container
          direction="row"
          // justify="space-between"
          // className={classes.medName}
          // alignItems="center"
        >
          <Grid item className={classes.medName}>
            <Autocomplete
              id={idx.toString()}
              margin="dense"
              classes={{ option: classes.autocomp }}
              // autoFocus
              autoSelect
              freeSolo
              disableClearable
              options={props.medSug}
              getOptionLabel={(option) => option}
              onChange={(e, value) => {
                props.handleChange(idx, "medName", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // name="medName"
                  label="Meds"
                  margin="dense"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: "60px", marginRight: "5px" }}
              id={idx.toString()}
              label="Dose"
              name="medQnt"
              margin="dense"
              defaultValue="1"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item className={classes.medSchd}>
            <Autocomplete
              //////////////////// Country
              id={idx.toString()}
              margin="dense"
              classes={{ option: classes.autocomp }}
              autoFocus
              freeSolo
              disableClearable
              options={medSchd}
              getOptionLabel={(option) => option}
              onChange={(e, value) => {
                props.handleChange(idx, "medSchd", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Schd"
                  margin="dense"
                  InputProps={{
                    ...params.InputProps,
                    // autoComplete: "new-password",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item className={classes.medSchdTime}>
            <Autocomplete
              id={idx.toString()}
              margin="dense"
              classes={{ option: classes.autocomp }}
              autoFocus
              freeSolo
              // disableClearable
              options={medSchdTime}
              getOptionLabel={(option) => option}
              value={props.medList[idx].medTime}
              onChange={(e, value) => {
                // setSelectedValue(value);
                props.handleChange(idx, "medTime", value);
              }}
              renderInput={(params) => (
                <TextField
                  // defaultValue="After Eat"
                  {...params}
                  label="Schd Time"
                  margin="dense"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          {/* <Grid item className={classes.medSchdTime}>
            <Autocomplete
              id={idx.toString()}
              margin="dense"
              classes={{ option: classes.autocomp }}
              autoFocus
              freeSolo
              disableClearable
              options={medSchdTime}
              getOptionLabel={(option) => option}
              value={props.medTime || "After Eat"}
              onChange={(e, value) => {
                props.handleChange(idx, "medTime", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Schd Time"
                  margin="dense"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid> */}

          <Grid item className={classes.medCountField}>
            <TextField
              // autoFocus
              margin="dense"
              id={idx.toString()}
              data-id={idx}
              name="medCount"
              // value={val.medCount}
              label="Count"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid>
            <Tooltip title="Is from outside ?">
              <Checkbox
                checked={val.medOut}
                onChange={(e) => handleChangeCheckbox(e, idx)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Tooltip>
          </Grid>
          <Grid className={classes.fabBut}>
            {idx === props.medList.length - 1 ? (
              <Fab
                color="primary"
                aria-label="add"
                size="small"
                onClick={() => props.add()}
                className={classes.addBut}
              >
                <AddIcon />
              </Fab>
            ) : (
              <Fab
                color="inherit"
                aria-label="del"
                size="small"
                onClick={() => props.delete(val.index)}
              >
                <RemoveIcon />
              </Fab>
            )}
          </Grid>
        </Grid>
      </div>
    );
  });
};
export default VisitFormMedicine;
