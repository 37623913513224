import React from "react";
// import ReactToPrint, { PrintContextConsumer } from "react-to-print";
// import Invoice1 from "./InvTest/Invoice1";
// import Invoice1 from "./InvTest/Invoice1";
// import Invoice2 from "./InvTest/Invoice2";
// import Invoice3 from "./InvTest/Invoice3";
import Invoice4 from "./InvTest/Invoice4";
import queryString from "query-string";
import { hostInfo } from "../../store";
import CircularProgress from "@material-ui/core/CircularProgress";

// import { ComponentToPrint } from "./ComponentToPrint";

const invoice1 = {
  name: "Transaction",
  createdDate: "2018-03-16",
  dueDate: "2018-04-16",
  paymentMethod: "PayPal",
  id: "ABCD-1234",
  description: "Post-show cravings",
  items: [
    {
      description: "Shake Shack",
      amount: 9.59,
    },
    {
      description: "Crinkle Cut Fries",
      amount: 2.99,
    },
  ],
};

const invoice2 = { ...invoice1 };
invoice2.items = [
  ...invoice2.items,
  {
    description: "Strawberry Shake (Free)",
    originalAmount: 5.29,
    amount: 0,
  },
];

// const customer = {
//   name: "Lin-Manuel Miranda, Founder and Producer of Hamilton, the American Musical",
//   email: "me@linmanuel.com",
//   address: ["226 W 46th St", "New York, NY 10036"],
//   logoUrl:
//     "http://p.fod4.com/p/media/c9c34f4e09/JqdTM3oTiqTcrbFoLdxb_Hamilton_200x200.jpg",
// };

// const company = {
//   name: "Shake Shack",
//   address: ["Madison Square Park", "New York, NY 10010"],
//   email: "contact@shakeshack.com",
//   logoUrl:
//     "https://www.shakeshack.com/wp-content/themes/shakeshack/images/shakeshack_logo.png",
// };

class PdfGen extends React.PureComponent {
  state = {
    open: false,
    visit_id: 0,
    patient_id: 0,
    visit: {},
    patientInfo: {},
    renderFlg: false,
  };

  async fetchVisit(visit_id) {
    const getToken = () => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});
      return cookies.authToken;
    };

    let url = hostInfo + "/getvisitbyid?visit_id=" + visit_id;
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    const data = await res.json();
    if (data != null) {
      await this.setState({ ...this.state, visit: data });
      // console.log(this.state);
      // console.log("visit data set ----");
    } else {
      // console.log("Visit data not found deom DB");
    }
  }

  async fetchPatientInfo(patient_id) {
    const getToken = () => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});
      return cookies.authToken;
    };

    let url = hostInfo + "/getpatientbyid?patient_id=" + patient_id;
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    const data = await res.json();
    if (data != null) {
      await this.setState({ ...this.state, patientInfo: data });
      // console.log("patient data set ----");
    } else {
      // console.log("Patient data not found deom DB");
    }
    await this.setState({ ...this.state, renderFlg: true });
  }

  async componentDidMount(props) {
    const values = queryString.parse(this.props.location.search);
    // console.log(values);
    let visit_id = values.id;
    if (visit_id == null || visit_id == undefined) {
      window.location = "/dashboard";
    }

    this.setState({ ...this.state, visit_id: visit_id });
    // console.log("Fetching data of visit: ", visit_id);
    await this.fetchVisit(visit_id);
    // console.log(this.state.visit);

    // Get patient details
    let patient_id = this.state.visit.user_id;
    // console.log("Fetching data of patient: ", patient_id);
    await this.fetchPatientInfo(patient_id);
    // console.log(this.state.patientInfo);
  }

  // async componentDidMount(props) {
  //   console.log("Inside component did mount -------- ");

  //   try {
  //     // Get Visit id from url query argument
  //     const values = queryString.parse(this.props.location.search);
  //     const visit_id = values.id;

  //     let url = "http://" + hostInfo + "/getvisitbyid?visit_id=" + visit_id;
  //     await fetch(url)
  //       .then((res) => res.json())
  //       .then((data) => {
  //         // console.log("Data in reps ... ");
  //         // console.log(data);
  //         if (data != null) {
  //           this.setState({ ...this.state, visit: data });
  //         }
  //       });

  //     const patient_id = this.state.visit.user_id;
  //     console.log("Fetching patient details for patient id: ", patient_id);
  //     url = "http://" + hostInfo + "/getpatientbyid?patient_id=" + patient_id;
  //     await fetch(url)
  //       .then((res) => res.json())
  //       .then((data) => {
  //         // console.log("Data in reps ... ");
  //         // console.log(data);
  //         if (data != null) {
  //           this.setState({ ...this.state, patientInfo: data });
  //         }
  //       });
  //   } catch (error) {
  //     console.log("Got error in geting data for prescription -- ", error);
  //   }
  // }

  render() {
    if (this.state.renderFlg === false) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <div>
          <Invoice4
            visit_id={this.state.visit_id}
            visit={this.state.visit}
            patientInfo={this.state.patientInfo}
          />
          {/* <Invoice1
          invoices={[invoice1]}
          customer={customer}
          company={company}
          notes={
            <p>
              If you have any questions, please email{" "}
              <a href="mailto:contact@shakeshack.com">contact@shakeshack.com</a>
              .
            </p>
          }
        /> */}

          {/* <Invoice1 /> */}
          {/* <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <button onClick={handlePrint}>Print this out!</button>
            )}
          </PrintContextConsumer>
        </ReactToPrint> */}
          {/* <ComponentToPrint ref={(el) => (this.componentRef = el)} /> */}
        </div>
      );
    }
  }
}
// function PdfGen() {
//   // useEffect(() => {
//   //   // console.log("Fetching patient profile data for patient id: " + patient_id);
//   //   let url = "http://" + hostInfo + "/getpatientbyid?patinet_id=" + patient_id;
//   //   fetch(url)
//   //     .then((res) => res.json())
//   //     .then((data) => {
//   //       // setTimeout(() => setStory(data), 1500);
//   //       // console.log("Success ", data);
//   //       // if (!Object.entries(data).length) {
//   //       //   window.location = "/dashboard";
//   //       // }
//   //       if (data != null) setUser(data);
//   //     });
//   //   // .catch((error) => {
//   //   //   console.log("Error", error);
//   //   // });
//   // }, [patient_id]);

//   return (
//     <PDFViewer width="1000" height="600" className="app">
//       <Invoice invoice={invoice} />
//     </PDFViewer>
//   );
// }

export default PdfGen;
