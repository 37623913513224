import React, { useEffect, useState } from "react";
import { List, ListItem, Button } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import myLogo from "../../../../images/avatar/logo22.png";

import { Link, Redirect, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    height: 50,
    justifyContent: "center",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    // marginLeft: theme.spacing(1)
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();
  // const handleLogout = () => {
  //   localStorage.removeItem("authToken");
  //   history.push("/login");
  // };
  // const [decodedToken, setDecodedToken] = useState(null);
  const handleLogout = () => {
    // setOpen(null);
    // localStorage.removeItem("authToken");
    document.cookie =
      "authToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    history.push("/login");
  };

  const getCookie = (name) => {
    const cookieString = document.cookie;

    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [key, value] = cookie.split("=");
      acc[key] = value;
      return acc;
    }, {});
    return cookies[name];
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = getCookie("authToken");
      if (token) {
        const decodedToke = JSON.parse(atob(token.split(".")[1]));
        const expirationTime = decodedToke.exp * 1000; // Convert to milliseconds
        // setDecodedToken(decodedToke);
        const currentTime = Date.now();
        if (currentTime >= expirationTime) {
          // Token has expired, clear cookie and redirect to login
          document.cookie =
            "authToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
          history.push("/login");
        } else {
          // Calculate remaining time until expiration
          const remainingTime = expirationTime - currentTime;
          setTimeout(() => {
            // Clear cookie and redirect to login
            document.cookie =
              "authToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            history.push("/login");
          }, remainingTime);
        }
      } else {
        history.push("/login");
      }
    };

    checkTokenExpiration(); // Initial check
    const interval = setInterval(checkTokenExpiration, 60000); // Check every minute

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [history]);
  // const [notifications] = useState([]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar style={{ justifyContent: "space-between" }}>
        <Link to="/">
          {/* <Hidden mdDown> */}
          <img alt="Logo" height={"40px"} src={myLogo} />
          {/* </Hidden> */}
        </Link>

        <Button color="inherit" onClick={handleLogout}>
          Logout
        </Button>

        {/* <div className={classes.flexGrow} /> */}
        {/* <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton className={classes.signOutButton} color="inherit">
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
