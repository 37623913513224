import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import HtmlContentByInfo from "./HtmlContentByInfo";
import { IconButton } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";

const PrintHtml = (props) => {
  const { visit, classes, user } = props;
  // console.log(user);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div>
        {/* <Button
          className={classes.saveBtn}
          // onClick={() => this.exportPdf()}
          onClick={handlePrint}
          variant="outlined"
          size="small"
        >
          Print
        </Button> */}
        <IconButton
          className={classes.saveBtn}
          onClick={handlePrint}
          // variant="outlined"
          size="small"
          component="span"
        >
          <PrintIcon fontSize="medium" />
        </IconButton>
        <div style={{ display: "none" }}>
          <HtmlContentByInfo ref={componentRef} visit={visit} user={user} />
        </div>
      </div>
    </>
  );
};

export default PrintHtml;
