import React from "react";
import Chart from "react-apexcharts";
import { hostInfo } from "../../../../store";
// import ApexCharts from "ApexCharts";

class DrReference extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          // height: 350,
          // stacked: true,

          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
        },
        colors: ["#7FF096"],
        xaxis: {
          // type: "string",
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        title: {
          text: "Referred Counts",
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  async componentDidMount() {
    const refName = [];
    const refCount = [];
    const getToken = () => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});
      return cookies.authToken;
    };

    // console.log("Component DID MOUNT!");
    let url = hostInfo + "/getreferencecount";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data !== null) {
          // console.log("Got data length: ", data.length);
          // console.log(data);
          for (var i = 0; i < data.length; i++) {
            refName.push(data[i]["ref"]);
            refCount.push(data[i]["cnt"]);
          }
          // console.log(refName);
        }

        this.setState({
          ...this.state,
          options: {
            chart: {
              id: "monthly-revenue",
            },

            zoom: {
              enabled: true,
            },
            xaxis: {
              // type: "string",
              categories: refName,
            },
          },
          series: [
            {
              name: "Ref Count",
              data: refCount,
            },
          ],
        });
      });
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        // width={800}
        height={400}
      />
    );
  }
}

export default DrReference;
