import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { hostInfo } from "../../../store";

import { Grid, Box, Avatar, Typography, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontSize: 2,
  },
  inner: {
    minWidth: "100%",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
  },
  containerValue: {
    marginLeft: theme.spacing(3),
    wordWrap: "break-word",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },

  visitBox: {
    borderColor: "#ffc107",
  },
  actions: {
    justifyContent: "flex-end",
  },
  totalPaid: {
    color: "#fff",
    width: "150%",
    maxWidth: "100px",
    background:
      "radial-gradient( circle 1087px at 1.1% 107.6%, #1AB85E 0.7%, rgba(252,196,173,1) 33%, rgba(177,248,200,1) 77.9%, rgba(139,255,212,0.87) 98.1% )",
  },
  remain: {
    color: "#fff",
    width: "150%",
    maxWidth: "100px",
    background:
      "radial-gradient( circle 1087px at 1.1% 107.6%, #E8625F 0.7%, rgba(252,196,173,1) 33%, rgba(177,248,200,1) 77.9%, rgba(139,255,212,0.87) 98.1% )",
  },
  // active: {
  //   color: "#fff",
  //   backgroundColor: "#8C7B85",
  // },
}));

const PaymentHistory = (props) => {
  const classes = useStyles();
  const { patient_id } = props;
  const [paymentInfo, setpaymentInfo] = useState([]);
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    let url = hostInfo + "/getptpayinfo?patient_id=" + patient_id;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log("got payment history..: ");
        // console.log(res);
        if (res != null) {
          setpaymentInfo(res);
        }
      });
  }, [patient_id]);

  // console.log("Payment information ----------------------------");
  // console.log(paymentInfo);

  return (
    <div className={classes.root}>
      <Box
        boxShadow={2}
        // borderLeft={2}
        borderRadius={2}
        // borderColor={"#E6781E"}
        align="justify"
        bgcolor={"#EEF9DE"}
        height={60}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {/* <Grid item xs>
            <div className={classes.containerIcon}>
              <div className={classes.containerValue}> */}
          {/* <Typography color="textSecondary">{visit.id}</Typography> */}
          {/* <Typography color="textSecondary">Total (&#x20B9;)</Typography>
              </div>
              <div className={classes.containerValue}>
                <Avatar variant="rounded" className={classes.totalPaid}>
                  {paymentInfo.total}
                </Avatar>
              </div>
            </div>
          </Grid> */}
          <Grid item xs>
            <Tooltip title={"Total: " + paymentInfo.total} interactive>
              <div className={classes.containerIcon}>
                {/* <img src={patientNtIcon} width="30" height="30" alt="C" /> */}
                <div className={classes.containerValue}>
                  <Typography color="textPrimary" align="justify">
                    Remain (&#x20B9;)
                  </Typography>
                </div>
                <div className={classes.containerValue}>
                  <Avatar
                    variant="rounded"
                    style={{
                      color: "#fff",
                      width: "150%",
                      maxWidth: "100px",
                      backgroundColor:
                        paymentInfo.remain === 0
                          ? "#D1D1D1"
                          : paymentInfo.remain > 0
                          ? "#FF7396"
                          : "#76BA99",
                    }}
                  >
                    {paymentInfo.remain}
                  </Avatar>
                </div>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

PaymentHistory.propTypes = {
  className: PropTypes.string,
  //   caselist: PropTypes.array.isRequired,
};

export default PaymentHistory;
