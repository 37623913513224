import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { hostInfo } from "../../store";

import { PatientList } from "./components";
// import mockData from "./data";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const PatientMng = () => {
  const [userlist, setUserlist] = useState([]);
  // const [open, setOpen] = useState(false);
  const classes = useStyles();

  // const handleUserSubmit = () => {
  //   console.log("Inside user add submit...");
  // };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = (value) => {
  //   setOpen(false);
  //   // setSelectedValue(value);
  // };
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    let url = hostInfo + "/getptinfo";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res != null) setUserlist(res);
      });
  }, []);

  // console.log("first user list...");
  // console.log(userlist);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <PatientList patientlist={userlist} />
      </div>
    </div>
  );
};

export default PatientMng;
