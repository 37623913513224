// import React, { Component } from "react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
// import CircularProgress from "@material-ui/core/CircularProgress";
import PrintIcon from "@material-ui/icons/Print";
// import { hostInfo } from "../../../store";
import { wordGujMap } from "../../../store";
import {
  Container,
  // Row,
  // Col,
  Grid,
  Card,
  Divider,
  CardContent,
  Table,
} from "@material-ui/core";

//Import Image
// import logo from "../../../images/avatar/logo1.png";
// import Invoice3 from "./Invoice3";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(1),
    fontSize: 12,
  },
  deviderCls: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardContentCls: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function MedRender(props) {
  const { medList } = props;
  // console.log("Inside med render --------");
  // console.log(medList);
  if (medList.filter((med) => med.medOut === true).length > 0) {
    return (
      <div className="table-responsive">
        <Table className="table-nowrap">
          <thead>
            <tr>
              <th style={{ width: "70px" }}>No.</th>
              <th>Medicine</th>
              <th>Qnt-At</th>
              <th className="text-right">Count</th>
            </tr>
          </thead>
          <tbody>
            {medList
              .filter((med) => med.medOut === true)
              .map((med, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{med.medName}</td>
                  <td>
                    {med.medQnt ? med.medQnt : ""} {wordGujMap[med.medSchd]} (
                    {med.medTime})
                  </td>
                  <td className="text-right">{med.medCount}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  } else {
    return null;
  }
}
function RepRender(props) {
  const { repList } = props;
  const [repArr, setRepArr] = useState([]);
  // console.log("Inside rep render --------");
  // console.log(repList);
  repList.map((rep) => {
    if (rep.repName.length) {
      repArr.push(rep.repName);
    }
  });

  if (repArr.length > 0) {
    return (
      <div className="table-responsive">
        <br />
        <th>Reports</th>
        {repArr.join(",")}
        <br />
        <Divider />
        <br />
      </div>
    );
  }
  return null;
}

function RefRender(props) {
  const { refered, refInfo } = props;
  if (refered.length > 1) {
    return (
      <address>
        <br />
        <strong>Ref: </strong>
        <br />
        {refered}
        <br />
        {refInfo}
        <br />
      </address>
    );
  } else {
    return null;
  }
}

function NoteRender(props) {
  const { notes } = props;
  if (notes.length > 0) {
    return (
      <div className="table-responsive">
        <br />
        <th>Notes</th>
        {notes}
        <br />
        <Divider />
        <br />
      </div>
    );
  }
  return null;
}

function PaymentInfo(props) {
  const { total, paid } = props;

  return (
    <address>
      <strong>Payment</strong>
      <br />
      Total: {total}
      <br />
      Paid Amount: {paid}
    </address>
  );
}

function NameInfo(props) {
  const { firstname, lastname, address } = props;
  return (
    <address>
      <strong>Name</strong>
      <br />
      {firstname} {lastname}
      <br />
      {address}
    </address>
  );
}

const Invoice4 = (props) => {
  //Print the Invoice
  const { visit_id, visit, patientInfo } = props;

  const printInvoice = () => {};

  // console.log("inside Invoice4 -------");
  // console.log(visit);
  // console.log(patientInfo);

  const classes = useStyles();

  let medList = JSON.parse(visit.medicine_info);
  let repList = JSON.parse(visit.report_info);

  // console.log("String array------------: ");
  // console.log(repArr);

  return (
    <React.Fragment>
      {/* <MedRender medList={medList} /> */}
      <div className="page-content">
        <Container fluid="true">
          <Grid container direction="row">
            <Grid item container direction="column">
              <Card>
                <CardContent>
                  {/* <div className="invoice-title">
                    <h4 className="float-right font-size-16"># {visit.id}</h4>
                    <div className="mb-4">
                      <img src={logo} alt="logo" height="30" />
                    </div>
                  </div> */}
                  <hr />
                  <Grid container direction="row">
                    <Grid item xs className="mt-3">
                      {" "}
                      <NameInfo
                        firstname={patientInfo.firstname}
                        lastname={patientInfo.lastname}
                        // address={patientInfo.address}
                      />
                    </Grid>
                    <Grid item xs className="mt-3 text-right">
                      <address>
                        <strong>Visit Date:</strong>
                        <br />
                        {visit.date_time}
                        <br />
                        <br />
                      </address>
                    </Grid>
                  </Grid>
                  <div className="py-2 mt-3">
                    <h5 className="font-size-15 font-weight-bold">
                      Prescription
                    </h5>
                  </div>
                  <Divider className={classes.deviderCls} />
                  <MedRender medList={medList} />
                  <Divider className={classes.deviderCls} />
                  <RepRender repList={repList} />
                  <NoteRender notes={visit.notes} />
                  <RefRender
                    refered={visit.referred}
                    refInfo={visit.ref_info}
                  />
                  <div className="d-print-none">
                    <div
                      // className="float-right"
                      className={classes.cardContentCls}
                    >
                      <Link
                        to="#"
                        onClick={printInvoice}
                        className="btn btn-success waves-effect waves-light mr-2"
                      >
                        <PrintIcon />
                      </Link>
                      {/* <Link
                        to="#"
                        className="btn btn-primary w-md waves-effect waves-light"
                      >
                        Send
                      </Link> */}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
  // }
};

export default Invoice4;
