import React, { Component } from "react";
import { Container, Grid, Card, Divider, CardContent } from "@material-ui/core";

class PrintDrDetails extends Component {
  render() {
    const { drName, drMobile, drDesc } = this.props;

    return (
      <div
        id="pdf-element"
        style={{
          width: "90%",
          height: "100%",
          position: "relative",
          marginTop: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          fontSize: "24px",
        }}
      >
        <Container fluid="true">
          <Grid container direction="column">
            <Card>
              <CardContent>
                <Grid container direction="row" style={{ padding: "0px 20px" }}>
                  <Grid
                    item
                    xs
                    className="text-left"
                    style={{ paddingLeft: "25px", fontSize: "26px" }}
                  >
                    {drName}
                  </Grid>
                </Grid>

                <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
                <Grid container direction="row" style={{ padding: "0px 20px" }}>
                  <Grid
                    item
                    xs
                    className="text-left"
                    style={{ paddingLeft: "25px", fontSize: "26px" }}
                  >
                    {drDesc}
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
                <Grid container direction="row" style={{ padding: "0px 20px" }}>
                  <Grid
                    item
                    xs
                    className="text-left"
                    style={{ paddingLeft: "25px", fontSize: "26px" }}
                  >
                    {drMobile}
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: "20px" }} />
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default PrintDrDetails;
