//var uri = "https://localhost:8003/mfs100/"; //Secure
var uri = "http://localhost:8004/mfs100/"; //Non-Secure
// function GetMFS100Info() {
//   return GetMFS100Client("info");
// }

// function GetMFS100KeyInfo(key) {
//   var MFS100Request = {
//     Key: key,
//   };
//   var jsondata = JSON.stringify(MFS100Request);
//   return PostMFS100Client("keyinfo", jsondata);
// }
// export function CaptureFinger(quality, timeout) {
export async function CaptureFinger(quality, timeout) {
  // console.log("json data for capture...", quality, timeout, "-- start");

  var MFS100Request = {
    Quality: quality,
    TimeOut: timeout,
  };
  var jsondata = JSON.stringify(MFS100Request);
  // console.log("json data for capture...", jsondata);
  const res = await PostMFS100Client("capture", jsondata);
  //   const res = PostMFS100Client("capture", jsondata);
  // console.log("After capture finger---");
  return res;
}

// Devyang Muti Finger Capture
export async function CaptureMultiFinger(quality, timeout, nooffinger) {
  var MFS100Request = {
    Quality: quality,
    TimeOut: timeout,
    NoOfFinger: nooffinger,
  };
  var jsondata = JSON.stringify(MFS100Request);
  return PostMFS100Client("capturewithdeduplicate", jsondata);
}

export async function VerifyFinger(ProbFMR, GalleryFMR) {
  var MFS100Request = {
    ProbTemplate: ProbFMR,
    GalleryTemplate: GalleryFMR,
    BioType: "FMR", // you can paas here BioType as "ANSI" if you are using ANSI Template
  };
  var jsondata = JSON.stringify(MFS100Request);
  //   console.log("Before Verify--------", Date().toLocaleString());
  return PostMFS100Client("verify", jsondata);
  //   console.log("After Verify--------", Date().toLocaleString());
}

// function MatchFinger(quality, timeout, GalleryFMR) {
//   var MFS100Request = {
//     Quality: quality,
//     TimeOut: timeout,
//     GalleryTemplate: GalleryFMR,
//     BioType: "FMR", // you can paas here BioType as "ANSI" if you are using ANSI Template
//   };
//   var jsondata = JSON.stringify(MFS100Request);
//   return PostMFS100Client("match", jsondata);
// }

// function GetPidData(BiometricArray) {
//   var req = new MFS100Request(BiometricArray);
//   var jsondata = JSON.stringify(req);
//   return PostMFS100Client("getpiddata", jsondata);
// }

// function GetRbdData(BiometricArray) {
//   var req = new MFS100Request(BiometricArray);
//   var jsondata = JSON.stringify(req);
//   return PostMFS100Client("getrbddata", jsondata);
// }

async function PostMFS100Client(method, jsonData) {
  var res, json;
  // console.log("inside post 100 client...", method, jsonData);
  // console.log("Before --------", Date().toLocaleString());

  let url = uri + method;
  try {
    const data = await fetch(url, {
      method: "POST",
      //   mode: "no-cors",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: jsonData,
    }).then((response) => response.json());
    //   .then((response) => response.json())
    //   .then((response) => (res = { httpStaus: true, data: response }));
    res = { httpStaus: true, data: data };
  } catch (err) {
    // console.log(err);
    throw err;
  }
  // console.log("After --------", Date().toLocaleString());
  // console.log(res);

  return res;
}

// function GetMFS100Client(method) {
//   var res;
//   $.support.cors = true;
//   var httpStaus = false;
//   $.ajax({
//     type: "GET",
//     async: false,
//     crossDomain: true,
//     url: uri + method,
//     contentType: "application/json; charset=utf-8",
//     processData: false,
//     success: function (data) {
//       httpStaus = true;
//       res = { httpStaus: httpStaus, data: data };
//     },
//     error: function (jqXHR, ajaxOptions, thrownError) {
//       res = { httpStaus: httpStaus, err: getHttpError(jqXHR) };
//     },
//   });
//   return res;
// }

// function getHttpError(jqXHR) {
//   var err = "Unhandled Exception";
//   if (jqXHR.status === 0) {
//     err = "Service Unavailable";
//   } else if (jqXHR.status == 404) {
//     err = "Requested page not found";
//   } else if (jqXHR.status == 500) {
//     err = "Internal Server Error";
//   } else if (thrownError === "parsererror") {
//     err = "Requested JSON parse failed";
//   } else if (thrownError === "timeout") {
//     err = "Time out error";
//   } else if (thrownError === "abort") {
//     err = "Ajax request aborted";
//   } else {
//     err = "Unhandled Error";
//   }
//   return err;
// }

// function Biometric(BioType, BiometricData, Pos, Nfiq, Na) {
//   this.BioType = BioType;
//   this.BiometricData = BiometricData;
//   this.Pos = Pos;
//   this.Nfiq = Nfiq;
//   this.Na = Na;
// }

// function MFS100Request(BiometricArray) {
//   this.Biometrics = BiometricArray;
// }
