/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Button } from "@material-ui/core";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
import MainSearch from "../../../../../../views/MainSearch";
import MainSearchTxt from "../../../../../../views/MainSearchTxt/MainSearchTxt";

const isFingerPrintEnables = false;

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.my2.dark,
    padding: "2px 10px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.my2.main,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.my1.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.my1.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { className, sidebarVal, ...rest } = props;

  const classes = useStyles();

  // console.log(sidebarVal);

  return (
    <>
      {isFingerPrintEnables ? <MainSearch /> : <MainSearchTxt />}

      <List {...rest} className={clsx(classes.root, className)}>
        {sidebarVal.map((text, index) => (
          <ListItem button key={index}>
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={text.href}
            >
              <div className={classes.icon}>{text.icon}</div>
              {text.title}
              {/* <ListItemIcon>{text.icon}</ListItemIcon>
            <ListItemText primary={text.title} /> */}
            </Button>
          </ListItem>
        ))}
      </List>
    </>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
};

export default SidebarNav;
