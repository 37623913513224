import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";

import {
  Avatar,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { visitFormfields } from "../../../../store";

// import { getInitials } from "../../../../helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(1),
    fontSize: 12,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: "100%",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  notesCls: {
    padding: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  medRowOut: {
    color: "#8AA65C",
    fontWeight: "bold",
  },
  medRowIn: {
    color: "#282E29",
    // fontWeight: "bold",
  },
  expandGrid: {
    padding: theme.spacing(2),
    minWidth: "100%",
  },
  prescription: {
    minWidth: "40%",
    margin: theme.spacing(1),
  },
  reports: {
    minWidth: "20%",
    margin: theme.spacing(1),
  },
}));

const VisitDetailInfo = (props) => {
  const { visit } = props;

  const classes = useStyles();
  // const history = useHistory();

  // let contextTypes = {
  //   router: React.PropTypes.object,
  // };

  let medList = JSON.parse(visit.medicine_info);
  // console.log("medicine_info ----- ");
  // console.log(medList);
  let repList = JSON.parse(visit.report_info);
  // console.log("report_info ----- ");
  // console.log(repList);
  return (
    <div className={classes.expandGrid}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item className={classes.notesCls}>
          <Typography color="textPrimary" align="justify">
            {visitFormfields["notes"]}
          </Typography>
          <Typography color="textSecondary">
            {visit.notes ? visit.notes : "-"}
          </Typography>
        </Grid>
        <Grid item className={classes.notesCls}>
          <Typography color="textPrimary" align="justify">
            {visitFormfields["diaNotes"]}
          </Typography>
          <Typography color="textSecondary">
            {visit.dia_notes ? visit.dia_notes : "-"}
          </Typography>
        </Grid>
        <Grid item>
          <Box borderLeft={5} className={classes.prescription}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Prescription</TableCell>
                    <TableCell align="center">Qnt-At</TableCell>
                    <TableCell align="center">when</TableCell>
                    <TableCell align="right">#</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medList.map((med, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        align="left"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medName ? med.medName : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {"medQnt" in med ? med.medQnt : ""}{" "}
                        {med.medSchd ? med.medSchd : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medTime ? med.medTime : "-"}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medCount ? med.medCount : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item style={{ width: "250px" }}>
          <Box borderLeft={5} className={classes.reports}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Reports</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {repList.map((rep, idx) => (
                    <TableRow key={idx}>
                      <TableCell align="left">
                        {rep.repName ? rep.repName : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

VisitDetailInfo.propTypes = {
  className: PropTypes.string,
  // caselist: PropTypes.array.isRequired,
};

export default VisitDetailInfo;
