import React from "react";
// import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Paper, Divider, Typography } from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import { BackupNow } from "./components";
import Restore from "./components/Restore";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    // backgroundColor: "green",
  },
  title: {
    marginTop: theme.spacing(3),
    margin: theme.spacing(2),
  },
}));

const Maintenance = () => {
  const classes = useStyles();
  // const [dashboardCard, setDashboardCard] = useState({});

  return (
    <>
      <Typography
        className={classes.title}
        variant="h2"
        gutterBottom
        color="textSecondary"
      >
        Maintenance
      </Typography>
      <Divider className={classes.deviderCls} />
      <div className={classes.root}>
        <Container fluid className="main-content-container px-1">
          <Row>
            <Col lg="4">
              <div>
                <Typography
                  className="pl-2"
                  gutterBottom
                  variant="h5"
                  component="h2"
                  color="textSecondary"
                >
                  Data Backup
                </Typography>
              </div>
            </Col>
            <Grid container direction="row" className="pl-3">
              <Grid>
                <BackupNow />
              </Grid>
              {/* <Grid>
                <Restore />
              </Grid> */}
            </Grid>
            {/* <Typography className="pt-5">
              <BackupNow />
              <Restore />
            </Typography> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Maintenance;
