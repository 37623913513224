import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { hostInfo } from "../store";
import myLogo from "../images/avatar/logo1.png";

function SignUp() {
  const [signupData, setSignupData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [redirect, setRedirect] = useState(false);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSignupData({ ...signupData, [name]: value });
  };

  // const handleSignup = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await fetch("http://localhost:5050/signUp", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(signupData),
  //     });

  //     if (response.status === 200) {
  //       const data = await response.json();
  //       setSignupData({
  //         username: "",
  //         email: "",
  //         password: "",
  //       });
  //       setRedirect(true); // Redirect to the dashboard or any protected route
  //     } else {
  //       const errorData = await response.json();
  //       alert(errorData.error); // Show error message if signup fails
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("An error occurred during signup");
  //   }
  // };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${hostInfo}/signUp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signupData),
      });

      if (response.status === 200) {
        const data = await response.json();
        setSignupData({
          username: "",
          email: "",
          password: "",
        });
        setRedirect(true); // Redirect to the dashboard or any protected route
      } else {
        const errorData = await response.json();
        alert(errorData.error); // Show error message if signup fails
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred during signup");
    }
  };

  const useStyles = makeStyles((theme) => ({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      padding: theme.spacing(0.5),
      background:
        "radial-gradient( circle farthest-corner at 3.8% 11.2%, rgba(89,162,162,1)  0%, rgba(153,215,184,61) 90.2% )",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      background:
        "radial-gradient( circle farthest-corner at 3.8% 11.2%, rgba(89,162,162,1)  0%, rgba(153,215,184,61) 90.2% )",
      margin: theme.spacing(4, 0, 2),
      padding: "10px",
    },
  }));

  const classes = useStyles();

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src={myLogo}></Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label="User Name"
                name="username"
                autoComplete="username"
                value={signupData.username}
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={signupData.email}
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={signupData.password}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSignup}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default SignUp;
