// import React, { useEffect, useState } from "react";
// import { Switch, Redirect } from "react-router-dom";

// import { RouteWithLayout } from "./components";
// import { Main as MainLayout } from "./layouts";
// import { Route } from "react-router-dom";

// import {
//   Dashboard as DashboardView,
//   CaseList as CaseListView,
//   PatientMng as PatientMngView,
//   MedicineMng as MedicineMngView,
//   PatientProfile as PatientProfileView,
//   DrList as DrListView,
//   PdfGen as PdfGenView,
//   PatientList as PatientListView,
//   Assessment as AssessmentView,
//   // Maintenance as MaintenanceView,
//   PatientGroup as PatientGroupView,
//   Maintenance,
// } from "./views";
// import Login from "./views/Login";
// import SignUp from "./views/SignUp";

// const Routes = () => {
//   const authToken = localStorage.getItem("authToken");
//   // const [authToken, setAuthenticated] = useState(false);
//   // const [loading, setLoading] = useState(true);
//   // console.log(token);
//   // useEffect(() => {
//   //   // Define an asynchronous function to check authentication status
//   //   const checkAuth = async () => {
//   //     try {
//   //       // Make a GET request to the /dashboard endpoint using fetch
//   //       const response = await fetch("http://localhost:5050/dashboard", {
//   //         method: "GET",
//   //         headers: {
//   //           Authorization: `Bearer ${token}`, // Make sure token is defined and not null
//   //         },
//   //       });
//   //       console.log(response);
//   //       // If the response status is 200, set authenticated to true
//   //       if (response.status === 200) {
//   //         setAuthenticated(true);
//   //       } else {
//   //         // If response status is not 200, log the error
//   //         console.error("Authentication error:", response.statusText);
//   //       }
//   //     } catch (error) {
//   //       // Log any other errors that may occur during the request
//   //       console.error("Authentication error:", error.message);
//   //     } finally {
//   //       // Set loading to false regardless of the outcome
//   //       setLoading(false);
//   //     }
//   //   };

//   //   // Call the checkAuth function when the component mounts (empty dependency array)
//   //   checkAuth();
//   // }, [token]); // Include token in the dependency array to trigger useEffect when token changes

//   // if (loading) {
//   //   return <div>Loading...</div>;
//   // }
//   return (
//     <Switch>
//       <Route
//         exact
//         path="/"
//         // render={
//         // () => {
//         // return authToken ? (
//         // <Redirect to="/dashboard" />
//         // ) : (
//         //   <Redirect to="/signup" />
//         // );
//         // }
//         // }
//       >
//         <Redirect to="/dashboard" />
//       </Route>
//       {/* <Route
//         component={DashboardView}
//         // exact
//         // layout={MainLayout}
//         path="/dashboard"
//       /> */}
//       {/* <Route
//         component={SignUp}
//         // exact
//         // layout={MainLayout}
//         path="/signup"
//       /> */}

//       {/* {authToken && ( */}
//       <>
//         <RouteWithLayout
//           component={DashboardView}
//           exact
//           layout={MainLayout}
//           path="/dashboard"
//         />
//         <RouteWithLayout
//           component={PatientMngView}
//           exact
//           layout={MainLayout}
//           path="/patientmng"
//         />
//         <RouteWithLayout
//           component={PatientProfileView}
//           exact
//           layout={MainLayout}
//           path="/patients/patientprofile"
//         />
//         <RouteWithLayout
//           component={PatientListView}
//           exact
//           layout={MainLayout}
//           path="/patients"
//         />
//         <RouteWithLayout
//           component={PatientGroupView}
//           exact
//           layout={MainLayout}
//           path="/groups"
//         />

//         <RouteWithLayout
//           component={MedicineMngView}
//           exact
//           layout={MainLayout}
//           path="/medicinemng"
//         />
//         <RouteWithLayout
//           component={DrListView}
//           exact
//           layout={MainLayout}
//           path="/drlistview"
//         />
//         <RouteWithLayout
//           component={CaseListView}
//           exact
//           layout={MainLayout}
//           path="/cases"
//         />
//         <Route
//           component={PdfGenView}
//           exact
//           layout={MainLayout}
//           path="/prescription"
//         />
//         <RouteWithLayout
//           component={AssessmentView}
//           exact
//           layout={MainLayout}
//           path="/assessment"
//         />
//         <RouteWithLayout
//           component={Maintenance}
//           exact
//           layout={MainLayout}
//           path="/maintenance"
//         />

//         {/* <Redirect to="/dashboard" /> */}
//       </>
//       {/* )} */}
//       {/* <Redirect to="/dashboard" /> */}
//     </Switch>
//   );
// };

// export default Routes;

import React, { useEffect, useState } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout } from "./layouts";

import {
  Dashboard as DashboardView,
  CaseList as CaseListView,
  PatientMng as PatientMngView,
  MedicineMng as MedicineMngView,
  PatientProfile as PatientProfileView,
  DrList as DrListView,
  PdfGen as PdfGenView,
  PatientList as PatientListView,
  Assessment as AssessmentView,
  PatientGroup as PatientGroupView,
  Maintenance,
} from "./views";
import Login from "./views/Login";
import SignUp from "./views/SignUp";

const Routes = () => {
  const [authToken, setAuthToken] = useState(null);

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    const token = getToken();
    setAuthToken(token);
  }, []); // Empty dependency array to run only once on mount
  console.log(authToken);
  return (
    <Switch>
      {/* {authToken ? (
        <Route component={DashboardView} exact path="/dashboard" />
      ) : (
        <Route component={Login} exact path="/login" />
      )} */}
      {/* <Route
        path="/"
        exact
        render={() => {
          return authToken ? (
            <Redirect to="/dashboard" />
          ) : (
            <Redirect to="/login" />
          );
        }}
      /> */}
      <Route
        component={SignUp}
        exact
        path="/signup"
        // layout={MainLayout}
      />
      <Route
        component={Login}
        exact
        path="/login"
        // layout={MainLayout}
      />
      <Route
        component={Login}
        exact
        path="/"
        //  layout={MainLayout}
      />
      {
        // authToken && (
        <>
          <RouteWithLayout
            component={DashboardView}
            exact
            layout={MainLayout}
            path="/dashboard"
          />
          <RouteWithLayout
            component={PatientMngView}
            exact
            layout={MainLayout}
            path="/patientmng"
          />
          <RouteWithLayout
            component={PatientProfileView}
            exact
            layout={MainLayout}
            path="/patients/patientprofile"
          />
          <RouteWithLayout
            component={PatientListView}
            exact
            layout={MainLayout}
            path="/patients"
          />
          <RouteWithLayout
            component={PatientGroupView}
            exact
            layout={MainLayout}
            path="/groups"
          />
          <RouteWithLayout
            component={MedicineMngView}
            exact
            layout={MainLayout}
            path="/medicinemng"
          />
          <RouteWithLayout
            component={DrListView}
            exact
            layout={MainLayout}
            path="/drlistview"
          />
          <RouteWithLayout
            component={CaseListView}
            exact
            layout={MainLayout}
            path="/cases"
          />
          <Route component={PdfGenView} exact path="/prescription" />
          <RouteWithLayout
            component={AssessmentView}
            exact
            layout={MainLayout}
            path="/assessment"
          />
          <RouteWithLayout
            component={Maintenance}
            exact
            layout={MainLayout}
            path="/maintenance"
          />
        </>
        // )
        // : (
        //   <RouteWithLayout
        //     component={Login}
        //     exact
        //     layout={MainLayout}
        //     path="/login"
        //   />
        // )
      }
    </Switch>
  );
};

export default Routes;
